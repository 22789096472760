import { Component, OnInit, NgZone, ChangeDetectorRef, ViewChild, AfterContentChecked } from '@angular/core';
import { FormGroup, FormControl, Validators, FormArray, NgForm, FormBuilder } from '@angular/forms';

import * as L from 'leaflet';
import * as moment from 'moment';
import { AppService } from 'src/app/shared/services/app.service';

import { CustomValidators } from 'src/app/shared/customValidators/custom.validators'

import { Observable, forkJoin } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { Region, Disability, User } from 'src/app/shared/interfaces/interfaces';
import { MatCheckboxChange, MatSlideToggleChange } from '@angular/material';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AdminService } from '../../services/admin.service';
import { fadeIn } from 'src/app/shared/animations/animations';
import { trigger } from '@angular/animations';


@Component({
  selector: 'app-user-add',
  templateUrl: './user-add.component.html',
  styleUrls: ['./user-add.component.scss'],
  animations: [
    trigger('fadeIn', fadeIn()),
  ]
})
export class UserAddComponent implements OnInit {

  constructor(
    private appService: AppService,
    private zone: NgZone,
    private cdref: ChangeDetectorRef,
    private toast: ToastrService,
    private translate: TranslateService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private adminService: AdminService,
  ) { }

  userForm = new FormGroup({
    name: new FormControl('', Validators.required),
    surname: new FormControl('', Validators.required),
    birthday: new FormControl('', Validators.required),
    email: new FormControl(''),
    phone: new FormControl(''),
    caretakerForm: new FormGroup({
      name: new FormControl(''),
      surname: new FormControl(''),
      email: new FormControl(''),
      phone: new FormControl(''),
      desc: new FormControl(''),
      parent: new FormControl('')
    }),
    cardAmeaNumber: new FormControl(''),
    disability_desc: new FormControl('', Validators.required),
    constraints: new FormArray([], CustomValidators.multipleCheckboxRequireOne),
    constraints_sub: new FormArray([]),
    administrative_region: new FormControl('', Validators.required),
    municipality: new FormControl({ value: '', disabled: true }, Validators.required),
    address: new FormControl('', Validators.required),
    coordinates: new FormControl([], Validators.required),
    floor: new FormControl('', Validators.required),
    // disabilities: new FormArray([], CustomValidators.multipleCheckboxRequireOne),

  })

  @ViewChild('userFormDirective', { static: true }) private userFormDirective: NgForm
  hasCardAmea: boolean = false;
  parentValue = '0';
  user: User
  newUserStatus: 'accepted' | 'rejected' | 'pending'
  displayCaretaker = false;
  displayActivityProblems = true;
  activity_problem = 1;
  editMode = false;
  submitAndListUsers = true
  region: string[];
  city: string[];

  mapInit: L.MapOptions
  mapLayer = []
  mapZoom: number
  mapCenter: L.LatLng
  // disability_types = []
  contraints_types: Disability[] = []

  regions: Region[];
  administrativeRegions: string[];
  municipalities: string[];

  filteredAdminRegion$: Observable<string[]>
  filteredMunicipalities$: Observable<string[]>

  startDate = moment(new Date(1980, 0, 1))
  today = moment()

  canAddUsers: boolean

  ngOnInit() {






    this.userForm.controls.caretakerForm.patchValue({
      "parent": '0'
    });
    const openStreetMaps = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', { maxZoom: 18, attribution: '© <a tabindex="-1" href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, <a tabindex="-1" href="http://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery © <a tabindex="-1" href="http://mapbox.com">Mapbox</a>' })

    // console.log("1");

    this.mapInit = {
      layers: [openStreetMaps],
      zoom: 13,
      center: L.latLng(38.446237708502146, 23.602667517913137)
    }

    this.fetchMunicipalityDetails();



    // console.log("3");



    // this.canAddUsers = this.appService.loggedUser.info.find ( infoObj => infoObj.name === "registration_manager").obj.status === "accepted"

    // if (this.activatedRoute.snapshot.url[0].path === 'user_edit') this.editMode = true

    // forkJoin([this.fetchDisabilities$, this.fetchRegions$]).subscribe(
    //   data => {},
    //   error => console.error(error),
    //   () => {
    //     if  (this.editMode) {
    //       this.fetchUserOnEdit()
    //     }
    //   }
    // )
  }

  selectionChange(selection) {

    console.log("ppppp");

    console.log(typeof selection);
    console.log(selection);

    this.parentValue = selection
  }

  fetchUserOnEdit() {
    this.appService.fetch_manager_user(this.activatedRoute.snapshot.url[1].path).subscribe(
      data => {
        if (data && data[0]) this.populateUserForm(data[0])
      },
      error => {
        console.error(error)
        this.toast.error(this.translate.instant('ERRORS.USER_FETCH'))
      }
    )
  }

  populateUserForm(user: User) {
    console.log('user_____');
    console.log(user);
    console.log(user.disabilities.length);
    if (user.disabilities.length == 0) {
      this.displayActivityProblems = false;
      this.activity_problem = 0
      this.userForm.get('constraints').setValidators(null);
      this.userForm.get('constraints').clearValidators()
      this.userForm.get('constraints').reset();
      this.userForm.get('constraints').reset();
      this.userForm.get('disability_desc').reset()
      this.userForm.get('disability_desc').clearValidators()
      this.userForm.get('disability_desc').updateValueAndValidity()

    }

    this.user = user
    this.newUserStatus = user.status

    if (user.caretaker && (user.caretaker.carename || user.caretaker.caresurname || user.caretaker.careemail || user.caretaker.carephone || user.caretaker.caredescription)) {
      this.displayCaretaker = true
    }

    this.userForm.patchValue({
      "name": user.name,
      "surname": user.surname,
      "birthday": moment(user.birthday),
      "email": user.email.value,
      "phone": user.phoneNumber.value,
      "caretakerForm": {
        "name": user.caretaker.carename,
        "surname": user.caretaker.caresurname,
        "email": user.caretaker.careemail,
        "phone": user.caretaker.carephone,
        "desc": user.caretaker.caredescription,
      },
      "disability_desc": user.disabilitiesDesc,
      "administrative_region": user.region.administrative,
      "municipality": user.region.municipality,
      "address": user.address,
      "floor": user.floor,
      "coordinates": user.loc.coordinates,
      "cardAmeaNumber": user.cardAmeaNumber
    })

    const available_constaints_name_array = this.contraints_types.map(i => i.name)
    const constraints_form = available_constaints_name_array.map(i => user.disabilities.map(e => e.name).includes(i))
    const sub_constraints_form = constraints_form.map((e, i) => e ? user.disabilities.find(el => el.name === available_constaints_name_array[i]).sub.value : null)

    this.userForm.patchValue({
      "constraints": constraints_form,
      "constraints_sub": sub_constraints_form
    })


    this.userForm.controls.caretakerForm.patchValue({
      "parent": (this.user.caretaker.parent).toString()
    });


    this.pointOnMap(user.loc.coordinates[1], user.loc.coordinates[0])
  }

  changeDetection(event: MatCheckboxChange, controlIndex) { // detect disability checkbox changes
    if (event.checked === false) // if disability checkbox is unchecked, reset sub-disability validators
    {
      this.userForm.get('constraints_sub')['controls'][controlIndex].reset()
      this.userForm.get('constraints_sub')['controls'][controlIndex].clearValidators()
      this.userForm.get('constraints_sub')['controls'][controlIndex].updateValueAndValidity()
    }
    this.cdref.detectChanges();
  }

  hasUserStatusToggled(event: MatSlideToggleChange) {
    if (event.checked) {
      this.newUserStatus = 'accepted'
    } else {
      this.newUserStatus = 'rejected'
    }
  }

  hasCaretakerToggled(event: MatSlideToggleChange) {
    if (event.checked) {
      this.displayCaretaker = true
      this.userForm.get('caretakerForm').get('name').setValidators(Validators.required)
      this.userForm.get('caretakerForm').get('surname').setValidators(Validators.required)
    } else {
      this.displayCaretaker = false
      this.userForm.get('caretakerForm').get('name').clearValidators()
      this.userForm.get('caretakerForm').get('surname').clearValidators()
      this.userForm.get('caretakerForm').reset();
      this.parentValue = "0";

    }

  }


  hasActivityProblemToggled(event: MatSlideToggleChange) {
    if (!event.checked) {
      this.displayActivityProblems = false
      this.activity_problem = 0
      this.userForm.get('constraints').setValidators(null);
      this.userForm.get('constraints').clearValidators()
      this.userForm.get('constraints').reset()


      this.userForm.get('disability_desc').setErrors(null);
      this.userForm.get('disability_desc').clearValidators();

      this.userForm.get('disability_desc').reset()

    } else {
      this.displayActivityProblems = true
      this.activity_problem = 1

      this.userForm.get('constraints').setValidators([CustomValidators.multipleCheckboxRequireOne]);
      this.userForm.get('disability_desc').setValidators(Validators.required)

    }
    // this.cdref.detectChanges();

  }

  submitUser() {
    this.userForm.markAllAsTouched()
    // console.log(this.userForm.)
    // this.userForm.get('disabilities').markAsTouched()
    this.userForm.get('constraints').markAsTouched()
    this.userForm.get('constraints_sub').markAsTouched()
    this.userForm.get('coordinates').markAsTouched()


    console.log("this.userForm.get('constraints')++++++++++++++++++++++++++");
    console.log(this.userForm.get('constraints'));
    console.log('this.userForm');
    console.log(this.userForm);


    if (this.userForm.valid && this.mapLayer.length > 0) {
      const const_array = this.userForm.get('constraints').value
        .map((b, i) => b ? i : -1)
        .filter(i => i !== -1)
        .map(i => this.contraints_types[i].name)


      let disabilities_obj = []
      const_array.forEach(cons => {
        const con_ind = this.contraints_types.findIndex(e => e.name === cons)
        disabilities_obj.push({
          'name': cons,
          'sub': {
            'name': this.contraints_types[con_ind].sub.find(el => el.value === this.userForm.get('constraints_sub').value[con_ind]).name,
            'value': this.userForm.get('constraints_sub').value[con_ind]
          }
        })
        // constraints[con_ind].sub.filter( el => el.value === this.userForm.get('constraints_sub').value[con_ind])
      })


      let _region = this.region;
      let _city = this.city;

      if (this.userForm.value.administrative_region) {
        _region = this.userForm.value.administrative_region;
      }

      if (this.userForm.value.municipality) {
        _city = this.userForm.value.municipality;
      }



      const userToAdd = {
        name: this.userForm.value.name,
        surname: this.userForm.value.surname,
        birthday: this.userForm.value.birthday.format("YYYY-MM-DD"),
        email: this.userForm.value.email,
        phoneNumber: this.userForm.value.phone,
        loc: { type: "Point", coordinates: this.userForm.value.coordinates },
        region: { administrative: _region, municipality: _city },
        disabilities: disabilities_obj,
        disabilitiesDesc: this.userForm.value.disability_desc,
        address: this.userForm.value.address,
        floor: this.userForm.value.floor,

        carename: this.userForm.value.caretakerForm.name,
        caresurname: this.userForm.value.caretakerForm.surname,
        careemail: this.userForm.value.caretakerForm.email,
        carephone: this.userForm.value.caretakerForm.phone,
        caredescription: this.userForm.value.caretakerForm.desc,

        club: this.appService.loggedUser.info.find(e => e.name === 'registration_manager').obj._id,
        activity_problem: this.activity_problem,
        careparent: Number(this.parentValue),
        cardAmeaNumber: this.userForm.value.cardAmeaNumber
      }
      console.log("userToAdd__________");
      console.log(userToAdd);


      //add user id in object in case of edit
      if (this.editMode) userToAdd['_id'] = this.activatedRoute.snapshot.url[1].path

      this.appService.edit_user(userToAdd).subscribe(
        data => {

          if (this.editMode) {
            this.changeUserStatus(this.newUserStatus)
            // if (this.newUserStatus !== this.user.status) {
            // } else {
            //   this.toast.success(this.translate.instant('SUCCESS.SUCCESSFUL_EDIT'))
            // }
          } else {
            this.toast.success(this.translate.instant('SUCCESS.USER_ADD'))
          }

          if (this.submitAndListUsers) {
            this.router.navigate(['admin', 'users_overview'])
          } else {
            this.resetForm()
            this.userForm.get('caretakerForm').get('name').setErrors(null)
            this.userForm.get('caretakerForm').get('surname').setErrors(null)
            this.editMode = false
            this.router.navigate(['admin', 'user_add'])
          }
        },
        error => {
          console.error(error)
          this.toast.error(this.translate.instant('ERRORS.SERVICES_ERR'))
        }
      )
    }
  }

  resetForm() {
    this.userForm.reset()
    this.userFormDirective.resetForm()


    this.userForm.get('constraints_sub')['controls'].forEach(el => {
      el.reset()
      el.clearValidators()
      el.updateValueAndValidity()
    })
    this.displayCaretaker = false
    this.displayActivityProblems = true

    this.mapLayer = []
    this.mapZoom = 12,
      this.mapCenter = L.latLng(38.254028, 21.7583104)

    if (this.editMode) {
      this.fetchUserOnEdit()
    }
    this.navigate_top()

    this.fetchMunicipalityDetails();

  }

  navigate_top() {
    const anchor = document.getElementById('user_name')
    if (anchor) {
      anchor.focus()
      window.scroll(0, 0)
    }
  }

  onMapReady(map: L.Map) {
    // map.on ('layeradd', (ev:L.LeafletMouseEvent) => {
    //     map.setView(this.user.loc.coordinates, 18);
    // });
    map.on("click", (ev: L.LeafletMouseEvent) => {
      this.zone.run(() => {
        this.pointOnMap(ev.latlng.lat, ev.latlng.lng)

        this.appService.get_address(ev.latlng.lat, ev.latlng.lng).subscribe(
          data => {
            if (data.results.length > 0) {
              this.userForm.patchValue({ address: data.results[0].formatted_address })
            }
          },
          error => {
            console.error(error)
          }
        )
      })

    })
  }

  pointOnMap(lat, lng) {

    this.mapCenter = L.latLng(lat, lng);
    this.mapZoom = 18;

    this.mapLayer = []
    this.mapLayer.push(L.marker([lat, lng], {
      icon: L.icon({
        iconAnchor: [12, 41],
        iconUrl: "assets/marker-icon.png",
        shadowUrl: "assets/marker-shadow.png"
      })
    }))

    this.userForm.patchValue({ coordinates: [lng, lat] })
  }

  addressToMap(address) {
    if (address) {
      this.appService.get_coordinates(address).subscribe(
        data => {
          if (data.results.length > 0) {
            this.pointOnMap(data.results[0].geometry.location.lat, data.results[0].geometry.location.lng)
            this.userForm.patchValue({ address: data.results[0].formatted_address })
          }
        },
        error => {
          console.error(error)
        }
      )
    }
  }

  fetchDisabilities$: Observable<Disability[]> =
    this.appService.get_disabilities().pipe(
      map(
        data => {
          this.contraints_types = data
          this.appService.disabilities = data
          const formArray1 = this.userForm.get('constraints') as FormArray;
          const formArray2 = this.userForm.get('constraints_sub') as FormArray;
          this.contraints_types.forEach(x => {
            formArray1.push(new FormControl(false))
            formArray2.push(new FormControl(null))
          })
          return data
        },
        error => {
          console.error(error)
        }
      )
    )

  fetchRegions$: Observable<Region[]> =
    this.appService.get_regions().pipe(
      map(
        data => {
          this.administrativeRegions = data.map(el => el.region)
          this.regions = data
          this.appService.regions = data
          this.filteredAdminRegion$ = this.userForm.get('administrative_region').valueChanges
            .pipe(
              startWith(''),
              map(value => this._filterAdministrativeReg(this.administrativeRegions, value))
            )


          return data

        },
        error => {
          console.error(error)
        }
      )
    )

  _filterAdministrativeReg(options: string[], value: string): string[] {
    const filterValue = value ? value.toLowerCase() : ''
    const regionIndex = this.administrativeRegions.indexOf(value)

    if (regionIndex !== -1) {
      this.userForm.get('municipality').enable()
      this.municipalities = this.regions[regionIndex].municipalities.map(el => el.city.name)
      this.filteredMunicipalities$ = this.userForm.get('municipality').valueChanges
        .pipe(
          startWith(''),
          map(value => this._filterMunicipalities(this.municipalities, value))
        )
    } else if (this.userForm.get('municipality').value) {

      // this.userForm.get('municipality').reset()
      this.userForm.get('municipality').enable()
      this.userForm.patchValue({ municipality: this.city });
      this.userForm.get('municipality').disable()
      this.userForm.get('administrative_region').disable()
    }
    return options.filter(option => option.toLowerCase().includes(filterValue))
  }

  _filterMunicipalities(options: string[], value: string): string[] {
    const filterValue = value ? value.toLowerCase() : ''
    return options.filter(option => option.toLowerCase().includes(filterValue))
  }

  changeUserStatus(status: 'accepted' | 'rejected' | 'pending') {
    this.appService.change_user_status({ objectID: this.user._id, status: status === 'accepted' }).subscribe(
      data => {
        if (data.nModified === 1) {
          // this.toast.success(this.translate.instant('SUCCESS.USER_STATUS_UPDATED'))

          // Check if navigation does not redirect to 'Overview Users' view, and if so manually trigger userChanged$ subject so as to refresh the pending usersApplications number badges
          if (!this.submitAndListUsers) {
            this.adminService.userChanged$.next(this.user)
          }
        }
        this.toast.success(this.translate.instant('SUCCESS.SUCCESSFUL_EDIT'))
      },
      error => {
        console.error(error)
        this.toast.error(this.translate.instant('ERRORS.USER_STATUS_UPDATED'))
      }
    )
  }

  fetchMunicipalityDetails() {
    this.appService.getMunicipalityDetails().subscribe(
      data => {

        console.log("---------------Check it !!---------------");
        if (data[0].plugins && data[0].plugins.length > 0) {
          const cardAmeaPlugin = data[0].plugins.find((plugin: { name: string; }) => plugin.name === 'CardAmea');
          if (cardAmeaPlugin) {
            this.hasCardAmea = true;
          } else {
            this.hasCardAmea = false;
          }
        }
        console.log("---------------Check it !!---------------");

        console.log("---------------remove it !!---------------");

        // if (data[0].plugins && data[0].plugins[0].name === "CardAmea") {
        //   this.hasCardAmea = true;
        // } else {
        //   this.hasCardAmea = false;
        // }
        console.log("---------------remove it !!---------------");



        // let domainSplit = domain.split('//');
        // let getDomain = domainSplit[1].split('.');
        // console.log(getDomain[0]);
        // const openStreetMaps = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', { maxZoom: 18, attribution: '© <a tabindex="-1" href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, <a tabindex="-1" href="http://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery © <a tabindex="-1" href="http://mapbox.com">Mapbox</a>' })

        // this.mapInit = {
        //   layers: [openStreetMaps],
        //   zoom: data[0].center.zoom,
        //   center: L.latLng(data[0].center.coordinates[1], data[0].center.coordinates[0])
        // }

        this.mapZoom = data[0].center.zoom;
        this.mapCenter = L.latLng(data[0].center.coordinates[1], data[0].center.coordinates[0])

        this.region = data[0].administrative_region;
        this.city = data[0].city;

        this.canAddUsers = this.appService.loggedUser.info.find(infoObj => infoObj.name === "registration_manager").obj.status === "accepted"

        if (this.activatedRoute.snapshot.url[0].path === 'user_edit') this.editMode = true

        console.log(this.editMode);

        forkJoin([this.fetchDisabilities$, this.fetchRegions$]).subscribe(
          data => { },
          error => console.error(error),
          () => {
            if (this.editMode) {
              this.fetchUserOnEdit()
            }
          }
        )

        this.userForm.patchValue({ administrative_region: this.region });
        this.userForm.get('municipality').enable()
        this.userForm.patchValue({ municipality: this.city });

        // this.userForm.get('municipality').setValue({name:this.city});
        // this.userForm.get('administrative_region').valueChanges
        //   .pipe(
        //     startWith(''),
        //     map(value => {
        //       console.log(value);

        //       this._filterAdministrativeReg(this.region, value)
        //     })
        //   )


        //   this.userForm.get('municipality').enable()
        //   this.userForm.get('municipality').setValue({name:this.city});
        //   this.userForm.patchValue({municipality: this.city });

      },
      error => {
        console.error(error)
        this.toast.error(this.translate.instant('ERRORS.USER_FETCH'))
      }
    )
  }

}

const constraints = [
  { "name": "Mobility", "sub": [{ "name": "Partial", "value": 1 }, { "name": "PartialSupport", "value": 2 }, { "name": "Immobility", "value": 3 }] },
  { "name": "Vision", "sub": [{ "name": "LowSight", "value": 1 }, { "name": "Amblyopia", "value": 2 }, { "name": "Blind", "value": 3 }] },
  { "name": "Hearing", "sub": [{ "name": "HardHearing", "value": 2 }, { "name": "Deaf", "value": 3 }] },
  { "name": "Mental", "sub": [{ "name": "LimitedSupport", "value": 1 }, { "name": "ExtendedSupport", "value": 2 }, { "name": "DiffusedSupport", "value": 3 }] }
]



