import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import {MAT_MOMENT_DATE_FORMATS, MomentDateAdapter, MatMomentDateModule} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';

import { 
  MatToolbarModule,
  MatButtonModule,
  MatIconModule,
  MatFormFieldModule,
  MatInputModule,
  MatTabsModule,
  MatCheckboxModule,
  MatSelectModule,
  MatRadioModule,
  MatDatepickerModule,
  MatTableModule,
  MatSortModule,
  MatSidenavModule,
  MatListModule,
  MatCardModule,
  MatTooltipModule,
  MatAutocompleteModule,
  MatSlideToggleModule,
  MatExpansionModule,
  MatDialogModule,
  MatBadgeModule,
  MatPaginatorModule, 
  MatMenuModule
} from '@angular/material';


@NgModule({
  imports: [
    MatMomentDateModule,
    MatToolbarModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatTabsModule,
    MatButtonModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatTooltipModule,
    MatAutocompleteModule,
    MatSelectModule,
    MatRadioModule,
    MatDialogModule,
    MatTableModule,
    MatSortModule,
    MatSidenavModule,
    MatListModule,
    MatCardModule,
    MatSlideToggleModule,
    MatExpansionModule,
    MatBadgeModule,
    MatPaginatorModule,
    MatMenuModule
  ],
  exports: [
    MatMomentDateModule,
    MatToolbarModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatTabsModule,
    MatButtonModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatTooltipModule,
    MatAutocompleteModule,
    MatSelectModule,
    MatRadioModule,
    MatDialogModule,
    MatTableModule,
    MatSortModule,
    MatSidenavModule,
    MatListModule,
    MatCardModule,
    MatSlideToggleModule,
    MatExpansionModule,
    MatBadgeModule,
    MatPaginatorModule,
    MatMenuModule
  ],
  providers: [
    {provide: MAT_DATE_LOCALE, useValue: 'el-EL'},
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]}
  ]
})
export class AppMaterialModule { }
