import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRoute } from '@angular/router';

import { Observable, of } from 'rxjs';
import { AuthService } from '../auth/auth.service';
import { catchError, map } from 'rxjs/operators';
import { LoggedUser } from '../shared/interfaces/interfaces';
import { AppService } from '../shared/services/app.service';

import { HttpHeaders } from '@angular/common/http';



@Injectable({
  providedIn: 'root'
})

export class AuthGuardService implements CanActivate {

  constructor(private router: Router, private activatedRoute: ActivatedRoute, private authService: AuthService, private appService: AppService) { }

  canActivate(): Observable<boolean> {
    return this.authService.isAuthenticated().pipe(
      map( 
        (loggedUser: LoggedUser[]) => {
          if (loggedUser.length === 0) {
            localStorage.clear()
            this.router.navigate(['/'])
          } else {
            this.appService.loggedUser = loggedUser[0]
            this.appService.reqheaders = new HttpHeaders().set('token', localStorage.getItem('uuid'))

            // Logged User has roles, and is 'subscriber' is not in them - redirect to Admin site
            if (loggedUser[0].roles.length > 0 && !loggedUser[0].roles.some(e => e.value === 'subscriber')) {
              this.router.navigate(['admin'])  
            }
          }
                    
          // Returns true if Logged User object exists && user has 'subscriber' role among others
          return (loggedUser.length > 0 && loggedUser[0].roles.length > 0) && ( loggedUser[0].roles.some(e => e.value === 'subscriber') ) 
        }
      ),
      catchError(() => {
        this.router.navigate(['/'])
        return of(false)
      })
    )
  }

}
