import { Injectable } from '@angular/core';
import { BootstrapService } from '../../bootstrap/bootstrap.service';
import { TranslateService } from '@ngx-translate/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Disability, Region, LoggedUser, User, Club, Service } from '../interfaces/interfaces';
import { BehaviorSubject, of } from 'rxjs';
import { AuthService } from 'src/app/auth/auth.service';


@Injectable({
  providedIn: 'root'
})
export class AppService {

  constructor(
    private bootstrapService: BootstrapService,
    private translate: TranslateService,
    private http: HttpClient,
    private auth: AuthService
  ) {   }

  config = this.bootstrapService.getConfig()

  disabilities: Disability[] = []
  regions: Region[] = []

  loggedUser: LoggedUser
  reqheaders: HttpHeaders

  // user: User

  //
  //nonAdmin HTTP services
  //
  //

  register (user: {email:string, password:string}) {
    return this.http.post<{active: boolean, email: string}>(`${this.config.API}/users/register`, user
    )
  }

  fetch_user () {
    return this.auth.isAuthenticated()
  }

  update_user (user) {
    return this.http.post<any>(`${this.config.API}/users/update_subscriber`, user, {headers: this.reqheaders})
  }

  //
  //
  //*nonAdmin HTTP services
  //



  //
  //Admin HTTP services
  //
  //

  submitSector(params) {
    return this.http.post<any>(`${this.config.API}/users/register`, params)
  }

  // cityAdmin endpoints
  fetch_sectors(params:
    {
      status: 'accepted' | 'pending' | 'rejected',
      operations?: boolean,
      clubs?: boolean
    }
  )
  {
    return this.http.post<{operators?:Service[], clubs?:Club[]}>(`${this.config.API}/users/GetOperationsClubs`, params, {headers:this.reqheaders})
    // if (params.sector === 'clubs') return clubs
    // if (params.sector === 'services') return services
  }

  change_sector_active_status(params:
    {
      action: 'operation' | 'club',
      objectID: string,
      status: boolean
    }
  )
  {
    return this.http.post<{n: number, nModified: number, ok: number}>(`${this.config.API}/users/ChangeStatusOperationsClubs`, params, {headers:this.reqheaders})

  }

  // subscription_manager endpoints
  edit_user(user) {
    return this.http.post(`${this.config.API}/users/update_subscriber`, user, {headers:this.reqheaders})
  }
  delete_user(user_id) {
    console.log("__________________user_id");
    console.log(user_id);


    return this.http.post(`${this.config.API}/users/delete_amea`, user_id, {headers:this.reqheaders})
  }

  fetch_manager_users() {
    return this.http.post<User[]>(`${this.config.API}/users/getDetails`, null, {headers:this.reqheaders})
  }

  fetch_manager_user(_id: string) {
    return this.http.post<User[]>(`${this.config.API}/users/getUserDetails`, {id: _id}, {headers:this.reqheaders})
  }

  change_user_status(params:
      {
        objectID: string,
        status: boolean
      }
    )
  {
    params['action'] = 'amea'
    return this.http.post<{n: number, nModified: number, ok: number}>(`${this.config.API}/users/ChangeStatusSubscriber`, params, {headers:this.reqheaders})
  }

  // operators endpoints
  search_users(params) {
    return this.http.post<User[]>(`${this.config.API}/admin/operators/get_amea_position`, params, {headers:this.reqheaders})
  }

  count_users(params: {"administrative":string,"municipality":string}) {
    return this.http.post<{countRecord: 0}>(`${this.config.API}/users/user_count`, params, {headers:this.reqheaders})
  }

  //
  //
  //*Admin HTTP services
  //



  //
  //shared HTTP services
  //
  //

  get_disabilities() {
    if (this.disabilities.length > 0) {
      return of(this.disabilities)
    } else {
      return this.http.get<Disability[]>(`${this.config.API}/users/disabilities`)
    }
  }

  get_regions() {
    if (this.regions.length > 0) {
      return of(this.regions)
    } else {
      return this.http.get<Region[]>(`${this.config.API}/users/regions`)
    }
  }

  get_clubs( reqObj: {municipality:string} ) {
      return this.http.post<Club[]>(`${this.config.API}/users/GetClubs`, reqObj)
  }

  logout() {
    localStorage.clear()
    return this.http.post(`${this.config.API}/users/logout`, null, {headers:this.reqheaders})
  }

  try_activation(msg: string, email:string) {
    return this.http.get<{message: string}>(`${this.config.API}/users/account_email?msg=${msg}&email=${email}`)
  }

  checkEmailAvailability(username) {
    return this.http.post<{ message: string, status: boolean }>(`${this.config.API}/users/emailExisted`, {email: username})
  }

  //google geocode API queries
  get_address(latitude, longitude) {
    let query_lang = this.translate.currentLang;
    return this.http.get<any>(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&sensor=false&language=${query_lang}&key=${this.config.GOOGLE_KEY}`)
  }

  get_coordinates(address) {
    // this.city != 'testcity1' ? address += ` ,${this.city}` : address += ' ,patra';
    let query_lang = this.translate.currentLang;
    return this.http.get<any>(`https://maps.googleapis.com/maps/api/geocode/json?address=${address}&sensor=false&language=${query_lang}&key=${this.config.GOOGLE_KEY}`)
  }

  getMunicipalityDetails() {
    return this.http.get<{ message: string, status: boolean }>(`${this.config.API}/admin/settings/municipality`)
  }

  //
  //
  //*shared HTTP services
  //

  change_user_pass(pass_object) {
    const uuid = localStorage.getItem('uuid');
    pass_object.token = uuid;
    return this.http.post(`${this.config.API}/users/change_user_pass`, pass_object , { headers: this.reqheaders})
  }

  forgotPassword(email){
    return this.http.post(`${this.config.API}/users/forgot_password`, email , { headers: this.reqheaders})

  }
  forgotPasswordCheck(tokenAndEmail){
    return this.http.post(`${this.config.API}/users/forgot_password_check`, tokenAndEmail , { headers: this.reqheaders})
  }

  change_password(pass_object){
    return this.http.post(`${this.config.API}/users/change_password`, pass_object , { headers: this.reqheaders})

  }

  exportToExcel(pass_object){
    return this.http.post<Blob>(`${this.config.API}/users/getDetails`, pass_object , { headers: this.reqheaders, responseType: 'blob' as 'json'})

  }

  exportToPdf(id) {
    console.log(id);

    return this.http.get(`${this.config.API}/users/ameadetails/${id}`, { headers: this.reqheaders, responseType: 'blob' as 'json'})
  }



}
