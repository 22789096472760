import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { AppService } from 'src/app/shared/services/app.service';

@Injectable({
  providedIn: 'root'
})
export class OperatorRoleGuardService {

  constructor(
    private router: Router,
    private appService: AppService
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

    let canActivateClause: boolean = false

    canActivateClause = this.appService.loggedUser.roles.some( role => role.value === "operator")

    if (!canActivateClause) {
      this.router.navigate(['admin'])
    }

    return canActivateClause
  }
}
