import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AuthService } from 'src/app/auth/auth.service';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { CustomValidators } from 'src/app/shared/customValidators/custom.validators';
// import { AppService } from '../shared/services/app.service';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  constructor(private router: Router, private authService: AuthService) { }

  loginForm = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.pattern(CustomValidators.getEmailRegex())]),
    password: new FormControl('', Validators.required)
  })

  ngOnInit() {
    console.log("______________");

    console.log(this.router.url);
  }

  wrongCredentials = false
  notActivatedAccount = false
  notCertifiedSector = false
  serviceDown = false
  submitLogin() {
    this.wrongCredentials = false
    this.notActivatedAccount = false
    this.notCertifiedSector = false
    this.serviceDown = false

    if (this.loginForm.valid) {
      this.authService.login(this.loginForm.value).subscribe(
        data => {
          // localStorage.setItem('role', data.roles[0].value)
          localStorage.setItem('email', data.email)
          localStorage.setItem('uuid', data.uuid)

          // this.appService.user = data

          // console.log(this.appService)

          this.router.navigate(['admin'])
        },
        (error: HttpErrorResponse) => {
          console.error(error)
          this.loginForm.markAsPristine()

          if (error.status === 401) {
            console.error(error.error.message)
            if (error.error.message === "USER_DOESNOT_EXIST") this.wrongCredentials = true
            else if (error.error.message === "NOT_ACTIVATE_ACCOUNT") this.notActivatedAccount = true
            else if (error.error.message === "NOT_CERTIFIED") this.notCertifiedSector = true
            else this.serviceDown = true
          }
          else this.serviceDown = true
        }
      )
    }
  }


  handleChildClick() {
    console.log('Child component clicked in parent!12334');
    // logic to handle the click event from the child.
    this.router.navigate(['./']);

  }

  forgotPassword(){
    this.router.navigate(['/forgot_password']);
  }
}
