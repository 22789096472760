import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AdminComponent } from './admin/admin.component';
import { LoginComponent } from './admin/login/login.component';
import { RegistrationComponent } from './admin/registration/registration.component';
import { DashboardComponent } from './admin/dashboard/dashboard.component';
import { OverviewComponent } from './admin/dashboard/overview/overview.component';
import { UserAddComponent } from './admin/dashboard/user-add/user-add.component';
import { UserListComponent } from './admin/dashboard/user-list/user-list.component';
import { CertificationComponent } from './admin/dashboard/certification/certification.component';
import { CertifiedClubsListComponent } from './admin/dashboard/certified-clubs-list/certified-clubs-list.component';
import { CertifiedServicesListComponent } from './admin/dashboard/certified-services-list/certified-services-list.component';
import { AdminAuthGuardService } from './guards/admin-auth-guard.service';
import { CityAdminRoleGuardService } from './guards/adminRoleGuards/city-admin-role-guard.service';
import { OperatorRoleGuardService } from './guards/adminRoleGuards/operator-role-guard.service';
import { ManagerRoleGuardService } from './guards/adminRoleGuards/manager-role-guard.service';
import { MyaccountComponent } from './admin/dashboard/myaccount/myaccount.component';
import { ForgotPasswordComponent } from './shared/forgot-password/forgot-password.component';
import { ChangePasswordComponent } from './shared/change-password/change-password.component';

// import { UserApplicationsComponent } from './admin/dashboard/user-applications/user-applications.component';

// import { AppAdminModule } from './app-admin.module';



const routes: Routes = [

  // {
  //   path: 'forgot_password', component: ForgotPasswordComponent
  // },
  // { path: 'change_password', component: ChangePasswordComponent },
  {
    path: '', component: AdminComponent, children:
      [
        { path: 'login', component: LoginComponent },


        {
          path: 'registration', component: RegistrationComponent, children:
            [
              { path: 'club', component: RegistrationComponent },
              { path: 'operation', component: RegistrationComponent },
              // { path: '**', redirectTo: '', pathMatch: 'full' }
            ]
        },
        {
          path: '', component: DashboardComponent, canActivate: [AdminAuthGuardService], children:
            [
              { path: 'service_overview', component: OverviewComponent, canActivate: [OperatorRoleGuardService] },
              { path: 'user_add', component: UserAddComponent, canActivate: [ManagerRoleGuardService] },
              { path: 'user_edit/:id', component: UserAddComponent, canActivate: [ManagerRoleGuardService] },
              { path: 'users_overview', component: UserListComponent, canActivate: [ManagerRoleGuardService] },
              // { path: 'users_applications', component: UserApplicationsComponent},
              {
                path: 'certification', component: CertificationComponent, canActivate: [CityAdminRoleGuardService], children:
                  [
                    { path: 'clubs', component: CertificationComponent },
                    { path: 'operations', component: CertificationComponent },
                  ]
              },
              { path: 'certified_clubs', component: CertifiedClubsListComponent, canActivate: [CityAdminRoleGuardService] },
              { path: 'certified_services', component: CertifiedServicesListComponent, canActivate: [CityAdminRoleGuardService] },
              { path: 'my_account', component: MyaccountComponent, canActivate: [AdminAuthGuardService] },
              // { path: '**', redirectTo: '', pathMatch: 'full' }
            ]
        },
        { path: '**', redirectTo: '', pathMatch: 'full' }
      ]
  },
  { path: '*', redirectTo: '', pathMatch: 'full' }

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AppAdminRoutingModule { }
