import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { MediaMatcher } from '@angular/cdk/layout';
import { AppService } from 'src/app/shared/services/app.service';
import { AdminService } from 'src/app/admin/services/admin.service';

import { Router, ActivatedRoute } from '@angular/router';
import { Role } from 'src/app/shared/interfaces/interfaces';
import { Subscription, timer } from 'rxjs';

declare interface RouteInfo {
  path: string;
  title: string;
  icon: string;
  role: string[];
  badgeVar?: string;
}

export const ROUTES: RouteInfo[] = [
  { path: 'certified_clubs', title: 'CERTIFIED_CLUBS',  icon:'accessible', role:["cityAdmin"]},
  { path: 'certified_services', title: 'CERTIFIED_SERVICES',  icon:'business', role:["cityAdmin"]},
  { path: 'certification/clubs', title: 'APPLICATIONS_CLUBS',  icon:'list_alt', role:["cityAdmin"], badgeVar:'clubApplications'},
  { path: 'certification/operations', title: 'APPLICATIONS_OPERATIONS',  icon:'list_alt', role:["cityAdmin"], badgeVar:'serviceApplications'},
  { path: 'users_overview', title: 'USERS_OVERVIEW',  icon: 'people', role:["registration_manager"], badgeVar:'usersApplications'},
  // { path: 'users_applications', title: 'USERS_APPLICATIONS',  icon: 'inbox', role:["registration_manager"]},
  { path: 'user_add', title: 'USER_ADD',  icon:'person_add', role:["registration_manager"]},
  { path: 'service_overview', title: 'SERVICE_OVERVIEW',  icon:'location_searching', role:["operator"]}

  // { path: 'avg_resolution_time', title: 'ΑVG_TIME_PER_DEP',  icon:'av_timer'},
  // { path: 'resolved_analysis', title: 'RESOLVED_STATS',  icon:'check'},
];

export interface RoleWithSidenavItems extends Role {
  menuItems?:any
  roleName?:string
}

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  providers: [AdminService]
})

export class DashboardComponent implements OnInit {

  lgScreenQuery: MediaQueryList
  mdScreenQuery: MediaQueryList
  private _mobileQueryListener: () => void


  constructor(
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    private appService: AppService,
    public adminService: AdminService,
    private router: Router,
    private activatedRoute: ActivatedRoute
    // private dateAdapter: DateAdapter<any>,

  ) {
    this.lgScreenQuery = media.matchMedia('(max-width: 1200px)')
    this.mdScreenQuery = media.matchMedia('(max-width: 992px)')
    this._mobileQueryListener = () => {changeDetectorRef.detectChanges()}
    this.lgScreenQuery.addListener(this._mobileQueryListener)
    this.mdScreenQuery.addListener(this._mobileQueryListener)
  }


  menuItems: any[];


  username:string
  roles: RoleWithSidenavItems[]

  subscription$ = new Subscription()

  ngOnInit() {
    console.log("000000000000000000");

    this.username = this.appService.loggedUser.email
    this.roles = this.appService.loggedUser.roles

    this.roles.forEach( role => {
      role['menuItems'] = ROUTES.filter( menuItem => menuItem.role.includes(role.value) )
      role['roleName'] = this.appService.loggedUser.info.find(item => item.name === role.value).obj.name
    })

    // this.menuItems = ROUTES.filter( menuItem => menuItem.role.includes(this.role) )

    if (this.activatedRoute.children.length === 0) this.router.navigate(['admin', this.roles[0]['menuItems'][0].path])
    // if ( !(this.activatedRoute.children.length && ROUTES.map(el => el.path).includes(`/${this.activatedRoute.children[0].routeConfig.path}`)) ) {

    //   this.router.navigate(['admin', this.roles[0]['menuItems'][0].path])
    // }

    if (this.roles.some(role => role.value === "cityAdmin")) {
      console.log("city admin queries_________________");

      this.cityAdminQueries()

    }
    if (this.roles.some(role => role.value === "registration_manager")) {
      this.RegistrationManagerQueries()

      this.subscription$.add(
        this.adminService.userChanged$.subscribe(
          data => {
            if (data) this.RegistrationManagerQueries()
          }
        )
      )
    }



  }

  cityAdminQueries() {
    this.appService.fetch_sectors({ status: 'pending', operations: true, clubs: true }).subscribe(
      data => {
        console.log("data ");
        console.log(data);


        if (data.clubs.length) this.adminService.clubApplications = data.clubs.length
        if (data.operators.length) this.adminService.serviceApplications = data.operators.length
      }
    )
  }

  RegistrationManagerQueries() {
    this.appService.fetch_manager_users().subscribe(
      data => {
        // console.log("run RegistrationManagerQueries")
        // console.log(data)
        const pendingApplications = data.filter(user => user.status === 'pending')
        this.adminService.usersApplications = undefined
        if (pendingApplications.length) this.adminService.usersApplications = pendingApplications.length
      }
    )
  }


  logout() {
    this.appService.logout().subscribe(
      data => {},
      error => console.error(error)
    )
    this.router.navigate(['admin', 'login'])
  }



  ngOnDestroy() {
    this.lgScreenQuery.removeListener(this._mobileQueryListener)
    this.mdScreenQuery.removeListener(this._mobileQueryListener)
    this.subscription$.unsubscribe()
  }

  myAccount(){
    this.router.navigateByUrl('/admin/my_account');
  }
}

