import { Component, OnInit } from '@angular/core';

import * as L from 'leaflet';
import * as moment from 'moment';

import { AppService } from 'src/app/shared/services/app.service';
import { Router } from '@angular/router';
import { LoggedUser, User } from 'src/app/shared/interfaces/interfaces';
import { TranslateService } from '@ngx-translate/core';
import { trigger } from '@angular/animations';
import { fadeIn } from 'src/app/shared/animations/animations';

@Component({
  selector: 'app-display',
  templateUrl: './display.component.html',
  styleUrls: ['./display.component.scss'],
  animations: [
    trigger('fadeIn', fadeIn()),
  ]
})
export class DisplayComponent implements OnInit {

  mapInit: L.MapOptions
  mapLayer = []

  loggedUser: LoggedUser

  userInfo: User
  age: number
  lastUpdated: string
  lastLogged: string
  hasCaretaker = false



  constructor(
    private appService: AppService,
    private router: Router,
    private translate: TranslateService
    ) { }

  ngOnInit() {
    const openStreetMaps = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', { maxZoom: 18, attribution: '© <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, <a href="http://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery © <a href="http://mapbox.com">Mapbox</a>' })

    this.mapInit = {
      layers: [openStreetMaps],
      zoom: 12,
      center: L.latLng(38.254028, 21.7583104)
    }


    this.fetchUser()
  }

  fetchUser() {

    //load cached
    this.loggedUser = this.appService.loggedUser
    if (this.loggedUser.info.length) {
      this.userInfo = this.loggedUser.info.find(e => e.name === 'subscriber').obj
      this.displayUserInfo()
    }

    this.appService.fetch_user().subscribe(
      loggedUser => {
        this.loggedUser = this.appService.loggedUser = loggedUser[0]

        if (loggedUser[0].info.length) {

          this.userInfo = this.loggedUser.info.find(e => e.name === 'subscriber').obj
          this.displayUserInfo()
        }
      },
      error => console.error(error)
    )
    // this.loggedUser = this.appService.loggedUser

    // if (this.loggedUser.info.length) {
    //   this.userInfo = this.loggedUser.info.find(e => e.name === 'subscriber').obj
    //   this.displayUserInfo()
    // }

    console.log("user info______________________________");

  }

  displayUserInfo() {
    this.age = moment().diff(new Date(this.userInfo.birthday), 'years')

    this.lastUpdated = moment(new Date(this.userInfo.updated)).locale(this.translate.currentLang).format('D/M/YYYY, HH:mm')

    this.lastLogged = moment(new Date(this.loggedUser.last_login)).locale(this.translate.currentLang).format('D/M/YYYY, HH:mm')

    if (this.userInfo.caretaker && (this.userInfo.caretaker.carename || this.userInfo.caretaker.caresurname || this.userInfo.caretaker.careemail || this.userInfo.caretaker.carephone || this.userInfo.caretaker.caredescription)) {
      this.hasCaretaker = true
    }

    this.mapLayer.push(L.marker([this.userInfo.loc.coordinates[1], this.userInfo.loc.coordinates[0]], {
      icon: L.icon({
        iconAnchor: [12, 41],
        iconUrl: "assets/marker-icon.png",
        shadowUrl: "assets/marker-shadow.png"
      })
    }))
  }


  onMapReady(map: L.Map) {
    map.on('layeradd', (ev: L.LeafletMouseEvent) => {
      map.setView([this.userInfo.loc.coordinates[1], this.userInfo.loc.coordinates[0]], 18);
    });
  }

  logout() {
    this.appService.logout().subscribe(
      data => { },
      error => console.error(error)
    )
    this.router.navigate(['user', 'login'])
  }

}
