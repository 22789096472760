import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators, NgForm } from '@angular/forms';
import { AppService } from 'src/app/shared/services/app.service';
import { Router, ActivatedRoute } from '@angular/router';
import { CustomValidators } from 'src/app/shared/customValidators/custom.validators';
import { AuthService } from 'src/app/auth/auth.service';
import { ApplicationDialogComponent } from 'src/app/shared/dialogs/application-dialog/application-dialog.component';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material';
import { trigger } from '@angular/animations';
import { fadeIn } from 'src/app/shared/animations/animations';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  animations: [
    trigger('fadeIn', fadeIn()),
   ]
})
export class LoginComponent implements OnInit {

  constructor(
    private appService: AppService,
    private router: Router,
    private authService: AuthService,
    private dialog: MatDialog,
    private translate: TranslateService,
    private activatedRoute: ActivatedRoute
  ) { }

  loginForm = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.pattern(CustomValidators.getEmailRegex())]),
    password: new FormControl('', Validators.required)
  })

  signupForm = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.pattern(CustomValidators.getEmailRegex())], CustomValidators.checkEmailTaken(this.appService)),
    passwordForm: new FormGroup({
      password1: new FormControl('', [Validators.required, CustomValidators.passwordValidator()]),
      password2: new FormControl('', [Validators.required, CustomValidators.passwordValidator()])
    }, CustomValidators.matchingPassValidator),
    terms: new FormControl(false, Validators.requiredTrue),
  })

  formTabIndex = 0

  @ViewChild('signupFormDirective', {static: true}) private signupFormDirective: NgForm


  ngOnInit() {
    const urlSnapshot = this.activatedRoute.snapshot.url
    if (urlSnapshot.length > 1 && urlSnapshot[1].path === 'signup') {
      this.formTabIndex = 1
    }
  }

  wrongCredentials = false
  notActivatedAccount = false
  notCertifiedSector = false
  serviceDown = false
  submitLogin() {
    this.wrongCredentials = false
    this.notActivatedAccount = false
    this.notCertifiedSector = false
    this.serviceDown = false

    if (this.loginForm.valid) {
      this.authService.login(this.loginForm.value).subscribe(
        data => {
          console.log("--------------------------->>>>>data");
          console.log(data);
          
          localStorage.setItem('email', data.email)
          localStorage.setItem('uuid', data.uuid)
          this.appService.loggedUser = data
          console.log("---->>>>this.appService.loggedUser")
          console.log(this.appService.loggedUser)
          this.router.navigateByUrl('/profile');
          //this.router.navigate(['profile'])
        },
        error => {
          console.error(error)
          this.loginForm.markAsPristine()

          if (error.status === 401) {
            if (error.error.message === "USER_DOESNOT_EXIST") this.wrongCredentials = true
            else if (error.error.message === "NOT_ACTIVATE_ACCOUNT") this.notActivatedAccount = true
            else if (error.error.message === "NOT_CERTIFIED") this.notCertifiedSector = true
            else this.serviceDown = true
          }
          else this.serviceDown = true
        }
      )

    }
  }

  submitSignup() {
    this.signupForm.get('terms').markAsTouched()
    if (this.signupForm.valid) {
      this.appService.register({
        email: this.signupForm.get('email').value,
        password: this.signupForm.get('passwordForm').get('password1').value
      }).subscribe(
        data => {
          this.openRequestStatusDialog('success', this.translate.instant('SUCCESS.USER_REGISTER'))
          this.signupForm.reset()
          this.signupFormDirective.resetForm()
        },
        error => {
          console.error(error)
          if (error.status === 400) {
            // switch (error.error.message) {
            //   case 'ACCOUNT_NOT_SAVED':
            //     this.openRequestStatusDialog('error', this.translate.instant('ERRORS.ENTRY_DUPLICATE_ACCOUNT'))
            //     break
            //   case 'CLUB_NOT_SAVED':
            //   case 'OPERATION_NOT_SAVED':
            //     this.openRequestStatusDialog('error', this.translate.instant('ERRORS.ENTRY_DUPLICATE_SECTOR'))
            //     break
            //   default:
            //     this.openRequestStatusDialog('error', this.translate.instant('ERRORS.ENTRY_GENERIC'))
            // }
            this.openRequestStatusDialog('error', this.translate.instant('ERRORS.ENTRY_GENERIC'))
          } else {
            this.openRequestStatusDialog('error', this.translate.instant('ERRORS.SERVICES_ERR'))
          }
        }
      )
    }
  }

  openRequestStatusDialog(status: 'success' | 'error', message: string) {
    this.dialog.open(ApplicationDialogComponent, {data: {requestResult: status, requestMessage: message}})
  }


  forgotPassword(){
    this.router.navigate(['/forgot_password']);


  }
}
