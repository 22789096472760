import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

  constructor(private router: Router) { }

  routerLink:string

  ngOnInit() {
    this.updateLogoRouterLink (this.router.url)
    this.router.events.subscribe( (val) => {
      if (val instanceof NavigationEnd) {
        this.updateLogoRouterLink(val.urlAfterRedirects)
      }
    })
  }

  updateLogoRouterLink (currentUrl) {
    const splitUrl = currentUrl.split('/')
    if (splitUrl[1] && splitUrl[1] === "profile") {
      this.routerLink = "/profile"
    } else {
      this.routerLink = "/"
    }
  }
}
