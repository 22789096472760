import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class BootstrapService {

  constructor(private http: HttpClient) { }

  private config: configModel

  public getConfig(): configModel {
    return this.config
  }

  loadConfig() {
    return new Promise( (resolve, reject) => {
      this.http.get<configModel>('assets/env_specific/config.json').subscribe(
        response => {
          this.config = response
          resolve(true)
        }
      )
    })
  }
}

export interface configModel {
  "API": string,
  "GOOGLE_KEY": string
}