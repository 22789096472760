import { Component, OnInit } from '@angular/core';
import { AdminService } from 'src/app/admin/services/admin.service';

import * as moment from 'moment';
import * as L from 'leaflet';

import { User } from 'src/app/shared/interfaces/interfaces';
import { TranslateService } from '@ngx-translate/core';
import { AppService } from 'src/app/shared/services/app.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';


@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent implements OnInit {

  constructor(
    private adminService: AdminService,
    private translate: TranslateService,
    private appService: AppService,
    private toastr: ToastrService,
    private router: Router
  ) { }

  selectedUser: User
  age: number
  birthday: string
  lastUpdated: string

  mapInit: L.MapOptions
  mapLayer = []
  mapZoom: number
  mapCenter: L.LatLngExpression
  test = Date.now()
  hasCardAmeaNumber: boolean

  ngOnInit() {

    this.fetchMunicipalityDetails();
    const openStreetMaps = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', { maxZoom: 18, attribution: '© <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, <a href="http://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery © <a href="http://mapbox.com">Mapbox</a>' })
    this.mapInit = {
      layers: [openStreetMaps],
      zoom: 12,
      center: L.latLng(38.254028, 21.7583104)
    }

    // this.fetchUserDetails()

  }

  fetchMunicipalityDetails() {
    this.appService.getMunicipalityDetails().subscribe(
      data => {

        //  to check!!
        console.log("---------------Check it !!---------------");
        if (data[0].plugins && data[0].plugins.length > 0) {
          const cardAmeaPlugin = data[0].plugins.find((plugin: { name: string; }) => plugin.name === 'CardAmea');
          if (cardAmeaPlugin) {
            this.hasCardAmeaNumber = cardAmeaPlugin.value;
          }
        }
        console.log("---------------Check it !!---------------");


        console.log("---------------remove it !!---------------");
        // this.hasCardAmeaNumber = data[0].plugins[0].value;
        console.log("---------------remove it !!---------------");

        const openStreetMaps = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', { maxZoom: 18, attribution: '© <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, <a href="http://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery © <a href="http://mapbox.com">Mapbox</a>' })
        this.mapInit = {
          layers: [openStreetMaps],
          zoom: data[0].center.zoom,
          center: L.latLng(data[0].center.coordinates[1], data[0].center.coordinates[0])
        }

        this.fetchUserDetails()
      }
    )
  }


  fetchUserDetails() {
    this.adminService.selectedUser$.subscribe(
      user => {
        if (user) {
          this.selectedUser = user
          // this.selectedUser['formattedBirthday'] = moment(user.birthday).locale(this.translate.currentLang).format('LL')
          console.log("this.selectedUser____________________");
          console.log(this.selectedUser);


          this.age = moment().diff(new Date(this.selectedUser.birthday), 'years')

          this.lastUpdated = moment(new Date(this.selectedUser.updated)).locale(this.translate.currentLang).format('D/M/YYYY, HH:mm')

          this.mapZoom = 18
          this.mapCenter = [this.selectedUser.loc.coordinates[1], this.selectedUser.loc.coordinates[0]]

          this.mapLayer = [(L.marker(L.latLng(this.selectedUser.loc.coordinates[1], this.selectedUser.loc.coordinates[0]), {
            icon: L.icon({
              iconAnchor: [12, 41],
              iconUrl: "assets/marker-icon.png",
              shadowUrl: "assets/marker-shadow.png"
            })
          }))]
        }
      }
    )
  }

  changeUserStatus(status: 'accepted' | 'rejected') {
    this.appService.change_user_status({ objectID: this.selectedUser._id, status: status === 'accepted' }).subscribe(
      data => {

        if (data.nModified === 1) {
          this.toastr.success(this.translate.instant('SUCCESS.USER_STATUS_UPDATED'))
          this.selectedUser.status = status
          this.adminService.userChanged$.next(this.selectedUser)
        }
      },
      error => {
        console.error(error)
        this.toastr.error(this.translate.instant('ERRORS.USER_STATUS_UPDATED'))
      }
    )
  }

  navigateToUserEdit() {
    this.router.navigate(['admin', 'user_edit', this.selectedUser._id])
  }


  toPdf(){
    // console.log("this.selectedUser________");
    // console.log(this.selectedUser._id);
    const userId = this.selectedUser._id

    this.appService.exportToPdf(userId).subscribe((response: Blob) => {
      const file = new Blob([response], { type: 'application/pdf' });
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL, '_blank');
    }, error => {
      console.error('Error downloading PDF:', error);
    });

  }
}
