import { BrowserModule } from '@angular/platform-browser';

// import Angular core modules
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, LOCALE_ID, APP_INITIALIZER } from '@angular/core';

// import app modules
import { AppRoutingModule } from './app-routing.module';
import { AppMaterialModule } from './app-material.module';

// import app services
import { BootstrapService } from './bootstrap/bootstrap.service';

// import Angular dep modules
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { registerLocaleData } from '@angular/common';
import localeEl from '@angular/common/locales/el';

//import 3rd-party dep modules
import { TranslateModule, TranslateLoader, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { NgProgressModule } from '@ngx-progressbar/core';
import { NgProgressHttpModule } from '@ngx-progressbar/http';

import { LeafletModule } from '@asymmetrik/ngx-leaflet';

import { ToastrModule } from 'ngx-toastr'


//import app Components
import { AppComponent } from './app.component';
import { NonAdminComponent } from './nonAdmin/non-admin.component';
import { LandingComponent } from './landing/landing.component';
import { LoginComponent } from './nonAdmin/login/login.component';
import { NavbarComponent } from './nonAdmin/navbar/navbar.component';
import { FooterComponent } from './shared/footer/footer.component';
import { ProfileComponent } from './nonAdmin/profile/profile.component';
import { EditComponent } from './nonAdmin/profile/edit/edit.component';
import { DisplayComponent } from './nonAdmin//profile/display/display.component';
import { ApplicationDialogComponent } from './shared/dialogs/application-dialog/application-dialog.component';
import { AccountActivationComponent } from './accountActivation/account-activation.component';
import { PrivacyPolicyComponent } from './shared/privacy-policy/privacy-policy.component';
import { ForgotPasswordComponent } from './shared/forgot-password/forgot-password.component';
import { ChangePasswordComponent } from './shared/change-password/change-password.component';
import { AppAdminModule } from './app-admin.module';

// import { AdminComponent } from './admin/admin.component';




//load config.json + translation files
export function configProviderFactory(provider: BootstrapService) {
  return () => provider.loadConfig();
}

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
registerLocaleData(localeEl)


@NgModule({
  declarations: [
    AppComponent,
    NonAdminComponent,
    LandingComponent,
    LoginComponent,
    NavbarComponent,
    FooterComponent,
    ProfileComponent,
    EditComponent,
    DisplayComponent,
    ApplicationDialogComponent,
    AccountActivationComponent,
    PrivacyPolicyComponent,
    // ForgotPasswordComponent,
    // ChangePasswordComponent,


  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    AppMaterialModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    LeafletModule.forRoot(),
    ToastrModule.forRoot({positionClass: 'toast-top-center'}),
    NgProgressModule,
    NgProgressHttpModule,
    AppAdminModule,

  ],
  entryComponents: [ApplicationDialogComponent],
  providers: [
    BootstrapService,
    { provide: APP_INITIALIZER, deps: [BootstrapService], useFactory: configProviderFactory, multi: true },
    TranslateService,
    { provide: LOCALE_ID, deps: [TranslateService], useFactory: (TranslateService) => TranslateService.currentLang },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
