import { Component, OnInit } from '@angular/core';
import { Club, Service } from 'src/app/shared/interfaces/interfaces';
import { AppService } from 'src/app/shared/services/app.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute, Router, RouterEvent, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs';

import * as L from 'leaflet';

import { icon, Marker } from 'leaflet';
import { AdminService } from '../../services/admin.service';
import { trigger } from '@angular/animations';
import { fadeIn } from 'src/app/shared/animations/animations';
const iconRetinaUrl = 'assets/marker-icon-2x.png';
const iconUrl = 'assets/marker-icon.png';
const shadowUrl = 'assets/marker-shadow.png';
const iconDefault = icon({
  iconRetinaUrl,
  iconUrl,
  shadowUrl,
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  tooltipAnchor: [16, -28],
  shadowSize: [41, 41]
});
Marker.prototype.options.icon = iconDefault;


export interface EnhancedClub extends Club {
  accountDetails?: {
    name: string,
    surname: string,
    email: string
  },
  map?: any
}

export interface EnhancedService extends Service {
  accountDetails?: {
    name: string,
    surname: string,
    email: string
  },
  map?: any
}

@Component({
  selector: 'app-certification',
  templateUrl: './certification.component.html',
  styleUrls: ['./certification.component.scss'],
  animations: [ 
    trigger('fadeIn', fadeIn()),
   ]
})
export class CertificationComponent implements OnInit {

  constructor(private appService: AppService, private toast: ToastrService, private translate: TranslateService, private router: Router, private activatedRoute: ActivatedRoute, private adminService: AdminService) { }

  clubs: EnhancedClub[] = []
  services: EnhancedService[] = []
  clubsRadioValue: 'pending' | 'rejected' = 'pending'
  servicesRadioValue: 'pending' | 'rejected' = 'pending'
  noClubsFound = false
  noServicesFound = false

  sectorSelected: string
  subscription = new Subscription

  ngOnInit() {

    const urlFirstchild = this.activatedRoute.snapshot.firstChild 
    if (urlFirstchild) {
      this.sectorSelected = urlFirstchild.routeConfig.path
    } else {
      this.router.navigate(['admin','certification','clubs'])
    }

    this.subscription = this.router.events.subscribe(
      event => {
        if (event instanceof NavigationEnd) {
          const splitURL = event.urlAfterRedirects.split('/')
          this.sectorSelected = splitURL[splitURL.length - 1]

          this.sectorSelected === 'clubs' ? this.fetchClubsApplications() : this.fetchServicesApplications()
          // if (splitURL[splitURL.length - 1] === 'clubs') {
          //   this.sectorSelected = 'clubs'
          // }
          // else if (splitURL[splitURL.length - 1] === 'operations') {
          //   console.log('operations')

          // }
        }
      }
    )

    this.sectorSelected === 'clubs' ? this.fetchClubsApplications() : this.fetchServicesApplications()  

  }
  
  fetchClubsApplications() {
    // console.log(this.clubsRadioValue)
    this.appService.fetch_sectors({clubs:true, status:this.clubsRadioValue}).subscribe(
      data => { this.clubs = data.clubs },
      error => { },
      () => this.checkRemainingClubsApplications()
    )
  }
  
  fetchServicesApplications() {
    // console.log(this.servicesRadioValue)
    
    this.appService.fetch_sectors({operations:true, status:this.servicesRadioValue}).subscribe(
      data => { this.services = data.operators },
      error => { },
      () => this.checkRemainingServicesApplications()
    )
  }

  acceptApplication(applicant: Club | Service, index: number, applicant_type: "club" | "service") {
    console.log(applicant)
    console.log(index)
    console.log(applicant_type)
    this.changeSectorStatus(applicant, index, applicant_type, "accepted")
  }

  rejectApplication(applicant: Club | Service, index: number, applicant_type: "club" | "service") {
    console.log(applicant)
    console.log(index)
    console.log(applicant_type)
    this.changeSectorStatus(applicant, index, applicant_type, "rejected")
  }

  changeSectorStatus(applicant: Club | Service, index: number, applicant_type: "club" | "service", status: "rejected" | "accepted") {
    
    if (applicant_type === "club") {
      this.appService.change_sector_active_status({action: 'club', objectID: applicant._id, status: status === "accepted" }).subscribe(
        data => { },
        error => { error.status === 400 ? this.toast.error(this.translate.instant('ERRORS.SECTOR_STATUS_CHANGE')): this.toast.error(this.translate.instant('ERRORS.SERVICES_ERR')) },
        () => {
          if (status === "accepted") this.toast.success(this.translate.instant('SUCCESS.SECTOR_STATUS_ACCEPTED'))
          else this.toast.success(this.translate.instant('SUCCESS.SECTOR_STATUS_REJECTED'))
          
          this.clubs.splice(index, 1)
          this.checkRemainingClubsApplications()    
        }
      )
    } else {
      this.appService.change_sector_active_status({action: 'operation', objectID: applicant._id, status: status === "accepted"}).subscribe(
        data => { },
        error => { error.status === 400 ? this.toast.error(this.translate.instant('ERRORS.SECTOR_STATUS_CHANGE')): this.toast.error(this.translate.instant('ERRORS.SERVICES_ERR')) },
        () => {
          if (status === "accepted") this.toast.success(this.translate.instant('SUCCESS.SECTOR_STATUS_ACCEPTED'))
          else this.toast.success(this.translate.instant('SUCCESS.SECTOR_STATUS_REJECTED'))

          this.services.splice(index, 1)
          this.checkRemainingServicesApplications()
        }
      )
    }
  }

  checkRemainingClubsApplications() {
    this.noClubsFound = false
    if (this.clubs.length === 0) { this.noClubsFound = true }

    if (this.clubsRadioValue === 'pending') {
      this.adminService.clubApplications = undefined
      if (this.clubs.length > 0) this.adminService.clubApplications = this.clubs.length
    }
    
    this.clubs.forEach ( club => {
      this.addMapToSector(club, club.loc)
    })
  }

  checkRemainingServicesApplications() {
    this.noServicesFound = false
    if (this.services.length === 0) { this.noServicesFound = true } 

    if (this.servicesRadioValue === 'pending') {
      this.adminService.serviceApplications = undefined
      if (this.services.length > 0) this.adminService.serviceApplications = this.services.length
    }

    this.services.forEach ( service => {
      this.addMapToSector(service, service.loc)
    })
  }

  addMapToSector(sector, loc) {
    const openStreetMaps = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', { maxZoom: 18, attribution: '© <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, <a href="http://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery © <a href="http://mapbox.com">Mapbox</a>' })

    sector['map'] = {
      options: {
        layers:[openStreetMaps],
        zoom: 18,
        center: L.latLng(loc.coordinates[1], loc.coordinates[0])
      },
      layer:  L.marker([loc.coordinates[1], loc.coordinates[0]])
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe()
  }
}

