import { Component, OnInit, NgZone } from '@angular/core';
import * as L from 'leaflet';
import { AppService } from 'src/app/shared/services/app.service';
import { User, LoggedUser } from 'src/app/shared/interfaces/interfaces';

import * as moment from "moment";
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { trigger } from '@angular/animations';
import { fadeIn } from 'src/app/shared/animations/animations';

export interface enhancedUser extends User {
  dist?: { calculated: number }
  age?: number
}

@Component({
  selector: 'app-overview',
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.scss'],
  animations: [
    trigger('fadeIn', fadeIn()),
  ]
})
export class OverviewComponent implements OnInit {

  constructor(
    private appService: AppService,
    private zone: NgZone,
    private fb: FormBuilder
  ) { }


  searchForm: FormGroup;
  problemTypeOptions = [
    { label: 'WITH', value: 1, checked: true },
    { label: 'WITHOUT', value: 0, checked: false },
  ];
  issuesList = [
    { label: 'MOBILITY', value: 'Mobility', checked: true },
    { label: 'VISION', value: 'Vision', checked: true },
    { label: 'HEARING', value: 'Hearing', checked: true },
    { label: 'MENTAL', value: 'Mental' },
    { label: 'MENTALHEALTH', value: 'MentalHealth' },
  ];




  // searchForm = new FormGroup({
  //   address: new FormControl('', Validators.required),
  //   radius: new FormControl(200, Validators.required),
  // })

  mapInit: L.MapOptions
  mapLayer = []
  mapZoom: number;
  mapCenter: L.LatLngExpression;
  map: L.Map

  activeUsersCount: number;
  users: enhancedUser[] = []

  loggedUser: LoggedUser

  showResultsList = false

  canOverviewCity: boolean

  ngOnInit() {

    this.searchForm = this.fb.group({
      problemType: [[this.problemTypeOptions[0].value], Validators.required],
      issues: this.fb.group({
        selectedIssues: [
          this.issuesList
            .filter(issue => issue.checked)
            .map(issue => issue.value),
          this.atLeastOneCheckboxSelected(),
        ],
      }),
      address: ['', Validators.required],
      radius: [200, Validators.required]

    });

    // Subscribe to changes in the 'problemType' control
    this.searchForm.get('problemType').valueChanges.subscribe(value => {
      if (!value.includes(1)) {
        this.searchForm.get('issues.selectedIssues').setValue([]); // Clear selected issues
        this.searchForm.get('issues.selectedIssues').clearValidators(); // Clear validators
        this.searchForm.get('issues').disable();
      } else {
        this.searchForm.get('issues').enable();
        this.searchForm.get('issues.selectedIssues').setValidators(this.atLeastOneCheckboxSelected()); // Set validators
        this.searchForm.get('issues.selectedIssues').setValue(
          this.issuesList.filter(issue => issue.checked).map(issue => issue.value)
        );
      }

      this.searchForm.get('issues.selectedIssues').updateValueAndValidity(); // Trigger validation
    });




    const openStreetMaps = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', { maxZoom: 19, attribution: '© <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, <a href="http://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery © <a href="http://mapbox.com">Mapbox</a>' })
    this.mapInit = {
      layers: [openStreetMaps],
      zoom: 12,
      center: L.latLng(38.254028, 21.7583104)
    }

    this.fetchMunicipalityDetails();

    // this.canOverviewCity = this.appService.loggedUser.info.find ( infoObj => infoObj.name === "operator").obj.status === "accepted"

    // this.loggedUser = this.appService.loggedUser
    // // this.searchUsers([38.24546060262514, 21.7395401000976], 20000)
    // this.countActiveUsers()
  }

  // Custom validator to ensure at least one checkbox is selected
  atLeastOneCheckboxSelected() {
    return (control) => {
      const selectedIssues = control.value;
      return selectedIssues && selectedIssues.length > 0 ? null : { atLeastOneSelected: true };
    };
  }





  async fetchMunicipalityDetails() {
    await this.appService.getMunicipalityDetails().subscribe(
      async data => {
        // const openStreetMaps = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', { maxZoom: 18, attribution: '© <a tabindex="-1" href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, <a tabindex="-1" href="http://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery © <a tabindex="-1" href="http://mapbox.com">Mapbox</a>' })

        // this.mapInit = {
        //   layers: [openStreetMaps],
        //   zoom: data[0].center.zoom,
        //   center: L.latLng(data[0].center.coordinates[1], data[0].center.coordinates[0])
        // }

        this.mapZoom = data[0].center.zoom;
        this.mapCenter = L.latLng(data[0].center.coordinates[1], data[0].center.coordinates[0])

        this.canOverviewCity = this.appService.loggedUser.info.find(infoObj => infoObj.name === "operator").obj.status === "accepted"

        this.loggedUser = this.appService.loggedUser
        // this.searchUsers([38.24546060262514, 21.7395401000976], 20000)
        this.countActiveUsers()
      });
  }

  addressToMap() {
    if (this.searchForm.valid) {
      this.appService.get_coordinates(this.searchForm.get('address').value).subscribe(
        data => {
          if (data.results.length > 0) {
            this.searchForm.patchValue({
              address: data.results[0].formatted_address
            })

            this.emergencyPointToMap(data.results[0].geometry.location.lat, data.results[0].geometry.location.lng)
            // setTimeout( () => {
            //   this.focusView([data.results[0].geometry.location.lat, data.results[0].geometry.location.lng])
            // },1)
          }
        },
        error => {
          console.error(error)
        }
      )
    }
  }


  emergencyPointToMap(lat, lng) {

    setTimeout(() => {
      this.focusView([lat, lng])
    }, 1)

    let circleRad: L.Circle
    this.mapLayer = []

    circleRad = L.circle([lat, lng], { radius: this.searchForm.get('radius').value })

    this.mapLayer.push(circleRad)

    this.mapLayer.push(new L.Marker([lat, lng], {
      icon: L.icon({
        iconSize: [30, 30],
        iconAnchor: [15, 30],
        iconUrl: 'assets/images/emergency.png'
      })
    }))

    // this.map.fitBounds(circleRad.getBounds(), {
    //   padding: L.point(24, 24),
    //   animate: true
    // })
    let searchFilters = {
      // coordinates: [lat, lng],
      "loc": { "type": "Point", "coordinates": [lng,lat] },
      "maxdistance": this.searchForm.get('radius').value,
      // radius: this.searchForm.get('radius').value,
      "activity_problem": this.searchForm.get('problemType').value,
      "disabilities": this.searchForm.get('issues.selectedIssues').value
    }
    console.log("searchFilters_______________");
    console.log(searchFilters);


    // this.searchUsers([lat,lng], this.searchForm.get('radius').value)
    this.searchUsers(searchFilters)

  }


  // searchUsers(coordinates, radius) {
  searchUsers(searchFilters) {


    if (this.searchForm.valid) {


    this.showResultsList = true

    // const search_params = {
    //   "loc": { "type": "Point", "coordinates": [searchFilters.coordinates[1], searchFilters.coordinates[0]] },
    //   "maxdistance": searchFilters.radius
    // }

    this.appService.search_users(searchFilters).subscribe(
      data => {
        this.users = data
      },
      error => console.error(error),
      () => {
        this.users.forEach(el => {
          this.mapLayer.push(L.marker([el.loc.coordinates[1], el.loc.coordinates[0]], {
            icon: L.icon({
              iconSize: [40, 40],
              iconAnchor: [20, 40],
              iconUrl: 'assets/images/disabled_person.png',
              shadowUrl: 'assets/marker-shadow.png',
              shadowAnchor: [15, 40]
            })
          }))
          el['age'] = moment().diff(new Date(el.birthday), 'years')
        })
      }
    )
  }
  }

  focusView(coordinates: L.LatLngExpression) {
    this.mapZoom = 17
    this.mapCenter = coordinates
    this.map.invalidateSize()
  }

  onMapReady(map: L.Map) {
    this.map = map

    map.on("click", (ev: L.LeafletMouseEvent) => {
      this.zone.run(() => {
        this.emergencyPointToMap(ev.latlng.lat, ev.latlng.lng)
        // this.focusView([ev.latlng.lat, ev.latlng.lng])

        this.appService.get_address(ev.latlng.lat, ev.latlng.lng).subscribe(
          data => {
            if (data.results.length > 0) {
              this.searchForm.patchValue({
                address: data.results[0].formatted_address
              })
              // this.emergencyPointToMap(ev.latlng.lat, ev.latlng.lng)
            }
          },
          error => {
            console.error(error)
          }
        )
      })

    })
  }

  countActiveUsers() {
    this.appService.count_users(this.loggedUser.info.find(el => el.name === 'operator').obj.region).subscribe(
      data => this.activeUsersCount = data.countRecord,
      error => console.error(error)
    )
  }
}



