import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { CustomValidators } from 'src/app/shared/customValidators/custom.validators';
import { AppService } from 'src/app/shared/services/app.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private appService: AppService,
    private toast: ToastrService,
    private translate: TranslateService,
  ) { }


  changePasswordForm = new FormGroup({
    password1: new FormControl('', [Validators.required, CustomValidators.passwordValidator()]),
    password2: new FormControl('', [Validators.required, CustomValidators.passwordValidator()]),

  }, CustomValidators.matchingPassValidator)

  // passwordForm: new FormGroup({
  //   password1: new FormControl('', [Validators.required, CustomValidators.passwordValidator()]),
  //   password2: new FormControl('', [Validators.required, CustomValidators.passwordValidator()])
  // }, CustomValidators.matchingPassValidator),

  tokenAndEmail: { token: string, email: string };
  pass_object: { token: string, password: string, verifyPassword: string }

  ngOnInit() {

    this.activatedRoute.queryParams.subscribe(params => {
      let token: string = params['msg'];
      let email: string = params['email'];

      this.tokenAndEmail = {
        token: token,
        email: email
      };
      console.log('tokenAndEmail:', this.tokenAndEmail);
      this.appService.forgotPasswordCheck(this.tokenAndEmail).subscribe(
        data => {
          console.log("data__________");
          console.log(data);
          if (data["msg"] === "NO_USER_FOUND") {

            // this.toast.error(this.translate.instant('ERRORS.SERVICES_ERR'))
            this.toast.error("No user found with provided details!");

            this.router.navigate(['./']);

          } else if (data["msg"] === "USER_FOUND") {
            console.log("USER_FOUND");

          }
        }
      );
    }, (error) => {
      console.log(error);

      this.toast.error(this.translate.instant('ERRORS.SERVICES_ERR'))
      this.router.navigate(['./']);
    });

  }
  handleChildClick() {
    this.router.navigate(['./']);

  }

  submit() {

    if (this.changePasswordForm.valid) {
      this.changePasswordForm.value
      console.log("______________");
      console.log(this.changePasswordForm.value);

      let token = this.tokenAndEmail['token'];

      this.pass_object = {
        token: token,
        password: this.changePasswordForm.value.password1,
        verifyPassword: this.changePasswordForm.value.password2
      }

      console.log("00000");
      console.log(this.pass_object);


      this.appService.change_password(this.pass_object).subscribe(
        data => {
          console.log("data change_password");

          console.log(data);

          if (data["msg"] === "PASSWORD_UPDATED") {

            this.toast.success('Ο κωδικός σας άλλαξε επιτυχώς.')
            this.router.navigate(['admin', 'login'])


          } else {
            this.toast.error(this.translate.instant('ERRORS.SERVICES_ERR'))
            this.router.navigate(['./']);

          }

        }, (error) => {
          console.log(error);

          this.toast.error(this.translate.instant('ERRORS.SERVICES_ERR'))
          this.router.navigate(['./']);
        }
      );
    }

    this.router.navigate(['./']);

  }


}
