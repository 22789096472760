import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource, MatSort, MatDialog } from '@angular/material';
import { Club, Service } from 'src/app/shared/interfaces/interfaces';
import { Subscription } from 'rxjs';
import { AppService } from 'src/app/shared/services/app.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationDialogComponent } from 'src/app/shared/dialogs/confirmation-dialog/confirmation-dialog.component';
import { trigger } from '@angular/animations';
import { fadeIn } from 'src/app/shared/animations/animations';

@Component({
  selector: 'app-certified-services-list',
  templateUrl: './certified-services-list.component.html',
  styleUrls: ['./certified-services-list.component.scss'],
  animations: [ 
    trigger('fadeIn', fadeIn()),
   ]
})
export class CertifiedServicesListComponent implements OnInit {

  constructor(
    private appService: AppService,
    private toast: ToastrService,
    private translate: TranslateService,
    private dialog: MatDialog
  ) { }

  displayedColumns = ['name', 'address', 'phone', 'registration_date', 'active']

  dataSource = new MatTableDataSource<Service>()
  

  @ViewChild(MatSort, {static: true}) sort: MatSort;
  subscriptions = new Subscription

  sectors: Service[] = []


  ngOnInit() {
    this.fetchCertifiedsectors()
  }

  fetchCertifiedsectors() {
    this.appService.fetch_sectors({ operations: true, status: 'accepted' }).subscribe(
      data => { this.sectors = data.operators },
      error => { console.error(error) },
      () => {
        this.dataSource.data = this.sectors
        this.dataSource.sort = this.sort
        this.dataSource.sortingDataAccessor = (item, property): string | number => {
          switch (property) {
            case 'registration_date': return new Date(item.created).getTime();
            default: return item[property];
          }
        }
      }
    )
  }

  toggleActiveStatus(row) {
    // row.active = !row.active
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {data: {message:"CONFIRM_SERVICE_CERTIFICATION_REVOKE", title: row.name}, disableClose: true})

    dialogRef.afterClosed().subscribe(
      data => {
        if (data) {
          this.appService.change_sector_active_status({
            action: "operation",
            objectID: row._id,
            status: false
          }).subscribe(
            data => {
              this.fetchCertifiedsectors()
              this.toast.success(this.translate.instant('SUCCESS.SECTOR_DEACTIVATED'))
            },
            error => {
              console.error(error)
              this.fetchCertifiedsectors()
              this.toast.error(this.translate.instant('ERRORS.SECTOR_DEACTIVATED'))
            }
          )
        } else {
          this.fetchCertifiedsectors()
        }
      }
    )

  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.dataSource.filter = filterValue;
  }
}
