import { Component, OnInit, NgZone, ViewChild, ElementRef, ChangeDetectorRef, AfterContentChecked } from '@angular/core';
import { FormGroup, FormControl, Validators, FormArray, FormBuilder } from '@angular/forms';
import { AppService } from 'src/app/shared/services/app.service';

import { CustomValidators } from 'src/app/shared/customValidators/custom.validators'

import * as moment from 'moment';
import * as L from 'leaflet';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { Disability, Region, LoggedUser, Club, User } from 'src/app/shared/interfaces/interfaces';
import { Observable, forkJoin } from 'rxjs';
import { MatCheckboxChange, MatSlideToggleChange } from '@angular/material';
import { startWith, map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { trigger } from '@angular/animations';
import { fadeIn } from 'src/app/shared/animations/animations';


@Component({
  selector: 'app-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss'],
  animations: [
    trigger('fadeIn', fadeIn()),
  ]
})
export class EditComponent implements OnInit, AfterContentChecked {

  constructor(
    private appService: AppService,
    private zone: NgZone,
    private cdref: ChangeDetectorRef,
    private toastr: ToastrService,
    private translate: TranslateService,
    private router: Router,
    private changeDetector: ChangeDetectorRef,
    private fb: FormBuilder
  ) { }

  editForm = new FormGroup({
    name: new FormControl('', Validators.required),
    surname: new FormControl('', Validators.required),
    birthday: new FormControl('', Validators.required),
    email: new FormControl({ value: '' }, [Validators.required, Validators.pattern(CustomValidators.getEmailRegex())]),
    phoneNumber: new FormControl('', Validators.pattern(/^[0-9]*$/)),
    address: new FormControl('', Validators.required),
    coordinates: new FormControl([], Validators.required),
    constraints: new FormArray([], CustomValidators.multipleCheckboxRequireOne),
    constraints_sub: new FormArray([]),
    constraintsDesc: new FormControl('', Validators.required),
    caretakerForm: new FormGroup({
      name: new FormControl(''),
      surname: new FormControl(''),
      email: new FormControl('', Validators.pattern(CustomValidators.getEmailRegex())),
      phone: new FormControl(''),
      desc: new FormControl(''),
      parent: new FormControl('')
    }),
    cardAmeaNumber: new FormControl(''),

    administrative_region: new FormControl('', Validators.required),
    municipality: new FormControl({ value: '' }, Validators.required),
    sector: new FormControl({ value: '' }, Validators.required),
    floor: new FormControl('', Validators.required)
  })

  mapInit: L.MapOptions
  mapLayer = []
  mapZoom: number
  mapCenter: L.LatLng
  _city;
  _region;

  contraints_types: Disability[] = []

  regions: Region[]
  administrativeRegions: string[]
  municipalities: string[]

  filteredAdminRegion$: Observable<string[]>
  filteredMunicipalities$: Observable<string[]>
  filteredSectors$: Observable<Club[]>
  filteredSectors: Club[]
  selectedSectorObj: Club

  disability_types = []

  loggedUser: LoggedUser
  userInfo: User
  lastUpdated: string
  displayCaretaker = false
  displayActivityProblems = true;
  activity_problem = 1;
  parentValue: string = '0' // Default value 'OXI'
  hasCardAmea: boolean = false;


  yourForm: FormGroup;

  startDate = moment(new Date(1980, 0, 1))
  @ViewChild('addressSearchInput', { static: true }) addressSearchInput: ElementRef;

  ngAfterContentChecked(): void {
    this.changeDetector.detectChanges();
  }

  ngOnInit() {
    // this.fetchUser()
    // this.yourForm = this.fb.group({
    //     name: [''],
    //     surname: [''],
    //     email: ['', Validators.pattern(CustomValidators.getEmailRegex())],
    //     phone: [''],
    //     desc: [''],
    //     parent: ['1'],  // Set the default value to '0' (OXI)
    //   })
    // ;
    this.editForm.controls.caretakerForm.patchValue({
      "parent": '0'
    });
    const openStreetMaps = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', { maxZoom: 18, attribution: '© <a tabindex="-1" href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, <a tabindex="-1" href="http://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery © <a tabindex="-1" href="http://mapbox.com">Mapbox</a>' })

    this.mapInit = {
      layers: [openStreetMaps],
      zoom: 12,
      center: L.latLng(38.254028, 21.7583104)
    }

    this.fetchMunicipalityDetails();

    // forkJoin([this.fetchDisabilities$, this.fetchRegions$]).subscribe(
    //   data => { setTimeout( () => {this.fetchUser()}, 0) },
    //   error => {console.error(error)}
    // )
   
    let disabilitiesLength = 0;
    if(this.appService.loggedUser.info.length > 0 ){
      disabilitiesLength = this.appService.loggedUser.info[0].obj.disabilities.length;
    }

    if (disabilitiesLength == 0) {
      this.displayActivityProblems = false
      this.activity_problem = 0;
      // this.editForm.get('constraints').setValidators(null);
      this.editForm.get('constraints').clearValidators()
      // this.editForm.get('constraints').reset()

      // this.editForm.get('constraintsDesc').setErrors(null);
      this.editForm.get('constraintsDesc').clearValidators();

      // this.editForm.get('constraintsDesc').reset()
    }


  }

  selectionChange(selection) {
    console.log("ppppp");

    console.log(typeof selection);
    console.log(selection);

    this.parentValue = selection
  }

  hasActivityProblemToggled(event: MatSlideToggleChange) {
    if (!event.checked) {
      this.displayActivityProblems = false
      this.activity_problem = 0
      this.editForm.get('constraints').setValidators(null);
      this.editForm.get('constraints').clearValidators()
      this.editForm.get('constraints').reset()


      this.editForm.get('constraintsDesc').setErrors(null);
      this.editForm.get('constraintsDesc').clearValidators();

      this.editForm.get('constraintsDesc').reset()

    } else {
      this.displayActivityProblems = true
      this.activity_problem = 1

      this.editForm.get('constraints').setValidators([CustomValidators.multipleCheckboxRequireOne]);
      this.editForm.get('constraintsDesc').setValidators(Validators.required)

    }
    // this.cdref.detectChanges();

  }




  fetchUser() {

    this.loggedUser = this.appService.loggedUser

    if (this.loggedUser.info.length) {
      console.log("1");

      this.userInfo = this.loggedUser.info.find(e => e.name === 'subscriber').obj
      this.displayInitialUserInfo()
    } else {
      console.log("2");
      this.displayCaretaker = true
      this.editForm.patchValue({
        email: this.loggedUser.email
      })
    }

  }

  displayInitialUserInfo() {
    this.lastUpdated = moment(new Date(this.userInfo.updated)).locale(this.translate.currentLang).format('D/M/YYYY, HH:mm')

    // if (this.userInfo.caretaker && (this.userInfo.caretaker.carename || this.userInfo.caretaker.caresurname || this.userInfo.caretaker.careemail || this.userInfo.caretaker.carephone || this.userInfo.caretaker.caredescription)) {
    //   this.displayCaretaker = true
    // }

    this.displayCaretaker = true


    this.editForm.patchValue({
      "name": this.userInfo.name,
      "surname": this.userInfo.surname,
      "constraintsDesc": this.userInfo.disabilitiesDesc,
      "birthday": moment(this.userInfo.birthday),
      "email": this.userInfo.email.value,
      "phoneNumber": this.userInfo.phoneNumber.value,
      "address": this.userInfo.address,
      "caretakerForm": {
        "name": this.userInfo.caretaker.carename,
        "surname": this.userInfo.caretaker.caresurname,
        "email": this.userInfo.caretaker.careemail,
        "phone": this.userInfo.caretaker.carephone,
        "desc": this.userInfo.caretaker.caredescription,
        // "parent":  this.userInfo.caretaker.parent
      },
      "administrative_region": this.userInfo.region.administrative,
      "municipality": this.userInfo.region.municipality,
      "floor": this.userInfo.floor,
      "cardAmeaNumber": this.userInfo.cardAmeaNumber

    })

    const available_constaints_name_array = this.contraints_types.map(i => i.name)
    const constraints_form = available_constaints_name_array.map(i => this.userInfo.disabilities.map(e => e.name).includes(i))
    const sub_constraints_form = constraints_form.map((e, i) => e ? this.userInfo.disabilities.find(el => el.name === available_constaints_name_array[i]).sub.value : null)

    this.editForm.patchValue({
      "constraints": constraints_form,
      "constraints_sub": sub_constraints_form
    })

    this.editForm.controls.caretakerForm.patchValue({
      "parent": (this.userInfo.caretaker.parent).toString()
    });

    this.filteredSectors$ = this.fetchFilteredSectors(this.userInfo.region.municipality).pipe(
      map(
        clubs => {
          this.editForm.patchValue({
            "sector": this.userInfo.club[0]._id
          })
          this.selectedSectorObj = this.userInfo.club[0]
          return clubs
        }
      )
    )

    this.coordinatesToMap(this.userInfo.loc.coordinates[1], this.userInfo.loc.coordinates[0])
  }


  submitEdit() {
    this.editForm.get('constraints').markAsTouched()
    this.editForm.get('constraints_sub').markAsTouched()
    this.editForm.get('coordinates').markAsTouched()

    // add club id
    if (this.editForm.valid && this.mapLayer.length > 0) {

      const const_array = this.editForm.get('constraints').value
        .map((b, i) => b ? i : -1)
        .filter(i => i !== -1)
        .map(i => this.contraints_types[i].name)

      let constraints_obj = []
      const_array.forEach(cons => {
        const con_ind = this.contraints_types.findIndex(e => e.name === cons)
        constraints_obj.push({
          'name': cons,
          'sub': {
            'name': this.contraints_types[con_ind].sub.find(el => el.value === this.editForm.get('constraints_sub').value[con_ind]).name,
            'value': this.editForm.get('constraints_sub').value[con_ind]
          }
        })
      })

      let __region = this._region;
      let __city = this._city;

      if (this.editForm.value.administrative_region) {
        __region = this.editForm.value.administrative_region;
      }

      if (this.editForm.value.municipality) {
        __city = this.editForm.value.municipality;
      }

      const userToEdit = {
        // _id: this.userInfo._id ,
        name: this.editForm.value.name,
        surname: this.editForm.value.surname,
        birthday: this.editForm.value.birthday.format(),
        email: this.editForm.value.email,
        phoneNumber: this.editForm.value.phoneNumber,
        loc: { type: "Point", coordinates: this.editForm.value.coordinates },
        region: { administrative: __region, municipality: __city },
        disabilities: constraints_obj,
        disabilitiesDesc: this.editForm.value.constraintsDesc,
        address: this.editForm.value.address,
        floor: this.editForm.value.floor,
        carename: this.editForm.value.caretakerForm.name,
        caresurname: this.editForm.value.caretakerForm.surname,
        careemail: this.editForm.value.caretakerForm.email,
        carephone: this.editForm.value.caretakerForm.phone,
        caredescription: this.editForm.value.caretakerForm.desc,
        club: this.editForm.value.sector,
        activity_problem: this.activity_problem,
        careparent: Number(this.parentValue),
        cardAmeaNumber: this.editForm.value.cardAmeaNumber,



      }


      console.log("user to edit_________");
      console.log(userToEdit);


      if (this.userInfo && this.userInfo._id) userToEdit['_id'] = this.userInfo._id


      this.appService.update_user(userToEdit).subscribe(
        data => {
          this.toastr.success(this.translate.instant('SUCCESS.USER_EDIT'))
          this.router.navigate(['profile'])
        },
        error => {
          console.error(error)
          this.toastr.error(this.translate.instant('ERRORS.SERVICES_ERR'))
        }
      )

    }


    // if (this.editForm.valid) {
    //   const userInfo = {
    //     name: this.editForm.get('name').value,
    //     surname: this.editForm.get('surname').value,
    //     birthday: moment(this.editForm.get('birthday').value).format("YYYY-MM-DD"),
    //     email: this.editForm.get('email').value,
    //     phoneNumber: this.editForm.get('phoneNumber').value,
    //     address: this.editForm.get('address').value,
    //     loc: { type: "Point", coordinates: this.editForm.get('coordinates').value },
    //     disabilities: this.editForm.get('disabilities').value
    //       .map((b, i) => b ? i : -1)
    //       .filter( i => i !== -1 )
    //       .map( i => this.disability_types[i]._id),
    //     disabilitiesDesc: this.editForm.get('constraintsDesc').value,
    //   }
    //   console.log(userInfo)
    //   this.appService.edit_user(userInfo).subscribe(
    //     data => {
    //       this.toastr.success(this.translate.instant('TOASTS.SUCCESSFUL_EDIT'))
    //     },
    //     error => {
    //       console.error
    //       this.toastr.error(this.translate.instant('ERRORS.SERVICES_ERROR'))
    //     }
    //   )
    // }


  }

  constraintChangeDetection(event: MatCheckboxChange, controlIndex) { // detect disability checkbox changes
    if (event.checked === false) // if disability checkbox is unchecked, reset sub-disability validators
    {
      this.editForm.get('constraints_sub')['controls'][controlIndex].reset()
      this.editForm.get('constraints_sub')['controls'][controlIndex].clearValidators()
      this.editForm.get('constraints_sub')['controls'][controlIndex].updateValueAndValidity()
    }
    this.cdref.detectChanges();
  }

  hasCaretakerToggled(event: MatCheckboxChange) {
    if (event.checked) {
      this.displayCaretaker = true
      this.editForm.get('caretakerForm').get('name').setValidators(Validators.required)
      this.editForm.get('caretakerForm').get('surname').setValidators(Validators.required)
    } else {
      this.displayCaretaker = false
      this.editForm.get('caretakerForm').get('name').clearValidators()
      this.editForm.get('caretakerForm').get('surname').clearValidators()
      this.editForm.get('caretakerForm').reset()
    }
  }

  fetchDisabilities$: Observable<Disability[]> =
    this.appService.get_disabilities().pipe(
      map(
        data => {
          this.contraints_types = data
          this.appService.disabilities = data
          const formArray1 = this.editForm.get('constraints') as FormArray;
          const formArray2 = this.editForm.get('constraints_sub') as FormArray;
          this.contraints_types.forEach(x => {
            formArray1.push(new FormControl(false))
            formArray2.push(new FormControl(null))
          })
          return data
        },
        error => {
          console.error(error)
        }
      )
    )


  fetchRegions$: Observable<Region[]> =
    this.appService.get_regions().pipe(
      map(
        data => {
          this.administrativeRegions = data.map(el => el.region)
          this.regions = data
          this.appService.regions = data
          this.filteredAdminRegion$ = this.editForm.get('administrative_region').valueChanges
            .pipe(
              startWith(''),
              map(value => this._filterAdministrativeReg(this.administrativeRegions, value))
            )
          return data
        },
        error => {
          console.error(error)
        }
      )
    )

  _filterAdministrativeReg(options: string[], value: string): string[] {
    this.editForm.get('administrative_region').setValidators(CustomValidators.autocompleteValidator(options))

    const filterValue = value ? value.toLowerCase() : ''
    const regionIndex = this.administrativeRegions.indexOf(value)

    if (regionIndex !== -1) {
      this.editForm.get('municipality').enable()
      this.municipalities = this.regions[regionIndex].municipalities.map(el => el.city.name)
      this.filteredMunicipalities$ = this.editForm.get('municipality').valueChanges
        .pipe(
          startWith(''),
          map(value => this._filterMunicipalities(this.municipalities, value))
        )
    } else {
      this.editForm.get('municipality').reset()
      this.editForm.get('sector').reset()
      this.editForm.get('municipality').disable()
      this.editForm.get('sector').disable()
      this.selectedSectorObj = undefined
      this.editForm.get('municipality').enable()
      this.editForm.patchValue({ municipality: this._city });
      this.editForm.get('municipality').disable()
    }

    return options.filter(option => option.toLowerCase().includes(filterValue))
  }

  _filterMunicipalities(options: string[], value: string): string[] {
    this.editForm.get('municipality').setValidators(CustomValidators.autocompleteValidator(options))

    const filterValue = value ? value.toLowerCase() : ''
    const municipalityIndex = this.municipalities.indexOf(value)

    if (municipalityIndex !== -1) {
      this.filteredSectors$ = this.fetchFilteredSectors(value)
    } else {
      this.editForm.get('sector').disable()
      this.editForm.get('sector').reset()
      this.selectedSectorObj = undefined
    }

    return options.filter(option => option.toLowerCase().includes(filterValue))
  }

  noSectorsFound = false
  fetchFilteredSectors(municipality: string) {
    return this.appService.get_clubs({ municipality: municipality }).pipe(
      map(
        clubs => {
          this.selectedSectorObj = undefined
          this.filteredSectors = clubs

          if (clubs.length) {
            this.noSectorsFound = false
            this.editForm.get('sector').enable()
          }
          else {
            this.noSectorsFound = true
            this.editForm.get('sector').disable()
          }

          return clubs
        }
      ))
  }

  displaySelectedSectorInfo(selection) {
    this.selectedSectorObj = this.filteredSectors.find(e => e._id === selection)
  }

  addressEdit(event) {
    // if (this.mapLayer.length) {
    //   this.mapLayer = []
    //   this.mapZoom = 12,
    //   this.mapCenter =  L.latLng(38.254028, 21.7583104)
    //   this.editForm.patchValue({'coordinates':[]})
    // }
    if (event.keyCode == 13) {
      this.addressToMap(this.addressSearchInput.nativeElement.value)
    }
  }

  coordinatesToMap(lat, lng) {
    this.mapCenter = L.latLng(lat, lng);
    this.mapZoom = 18;

    console.log(this.mapCenter);

    this.mapLayer = []
    this.mapLayer.push(L.marker([lat, lng], {
      icon: L.icon({
        iconAnchor: [12, 41],
        iconUrl: "assets/marker-icon.png",
        shadowUrl: "assets/marker-shadow.png"
      })
    }))

    this.editForm.patchValue({ coordinates: [lng, lat] })
  }

  addressToMap(address) {
    if (address) {
      this.appService.get_coordinates(address).subscribe(
        data => {
          if (data.results.length > 0) {
            this.coordinatesToMap(data.results[0].geometry.location.lat, data.results[0].geometry.location.lng)
            this.editForm.patchValue({ address: data.results[0].formatted_address })
          }
        },
        error => {
          console.error(error)
        }
      )
    }
  }


  onMapReady(map: L.Map) {


    map.on("click", (ev: L.LeafletMouseEvent) => {
      this.zone.run(() => {
        console.log(ev);

        this.coordinatesToMap(ev.latlng.lat, ev.latlng.lng)

        this.appService.get_address(ev.latlng.lat, ev.latlng.lng).subscribe(
          data => {
            if (data.results.length > 0) {
              this.editForm.patchValue({ address: data.results[0].formatted_address })
            }
          },
          error => {
            console.error(error)
          }
        )
      })
    })
  }

  navigate_top() {
    const anchor = document.getElementById('actions')
    if (anchor) {
      anchor.focus()
      window.scroll(0, 0)
    }
  }

  // async fetchMunicipalityDetails() {

  //   await this.appService.getMunicipalityDetails().subscribe(
  //     async data => {
  //       console.log(data);

  //       console.log(data[0].administrative_region);
  //       console.log(data[0].city);

  //       // let domainSplit = domain.split('//');
  //       // let getDomain = domainSplit[1].split('.');
  //       // console.log(getDomain[0]);

  //       // this.mapZoom = data[0].center.zoom;
  //       // this.mapCenter = L.latLng(data[0].center.coordinates[1], data[0].center.coordinates[0]);
  //       // this.mapLayer = [];

  //       // const openStreetMaps = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', { maxZoom: 18, attribution: '© <a tabindex="-1" href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, <a tabindex="-1" href="http://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery © <a tabindex="-1" href="http://mapbox.com">Mapbox</a>' })
  //       const openStreetMaps = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', { maxZoom: 18, attribution: '© <a tabindex="-1" href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, <a tabindex="-1" href="http://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery © <a tabindex="-1" href="http://mapbox.com">Mapbox</a>' })
  //       // this.mapInit = {
  //       //   layers: [openStreetMaps],
  //       //   zoom: data[0].center.zoom,
  //       //   center: L.latLng(parseFloat(data[0].center.coordinates[1]), parseFloat(data[0].center.coordinates[0]))
  //       // }

  //       this.mapInit = {
  //         layers: [openStreetMaps],
  //         zoom: 12,
  //         center: L.latLng(38.254028, 21.7583104)
  //       }

  //       console.log( this.mapInit);

  //       await forkJoin([this.fetchDisabilities$, this.fetchRegions$]).subscribe(
  //         data => { setTimeout( () => {this.fetchUser()}, 0) },
  //         error => {console.error(error)}
  //       )

  //     }
  //   )
  // }

  async fetchMunicipalityDetails() {

    await this.appService.getMunicipalityDetails().subscribe(
      async data => {
        console.log("---------------Check it !!---------------");
        if (data[0].plugins && data[0].plugins.length > 0) {
          const cardAmeaPlugin = data[0].plugins.find((plugin: { name: string; }) => plugin.name === 'CardAmea');
          if (cardAmeaPlugin) {
            this.hasCardAmea = true;
          } else {
            this.hasCardAmea = false;
          }
        }
        console.log("---------------Check it !!---------------");

        // const openStreetMaps = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', { maxZoom: 18, attribution: '© <a tabindex="-1" href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, <a tabindex="-1" href="http://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery © <a tabindex="-1" href="http://mapbox.com">Mapbox</a>' })

        // this.mapInit = {
        //   layers: [openStreetMaps],
        //   zoom: data[0].center.zoom,
        //   center: L.latLng(data[0].center.coordinates[1], data[0].center.coordinates[0])
        // }

        this.mapZoom = data[0].center.zoom;
        this.mapCenter = L.latLng(data[0].center.coordinates[1], data[0].center.coordinates[0])

        this._city = data[0].city;
        this._region = data[0].administrative_region;

        this.editForm.patchValue({ administrative_region: data[0].administrative_region });
        this.editForm.get('municipality').enable()
        this.editForm.patchValue({ municipality: data[0].city });
        this.editForm.get('municipality').disable()
        this.editForm.get('administrative_region').disable()
        this.filteredSectors$ = this.fetchFilteredSectors(data[0].city)
        this.editForm.get('sector').enable()

        await forkJoin([this.fetchDisabilities$, this.fetchRegions$]).subscribe(
          data => { setTimeout(() => { this.fetchUser() }, 0) },
          error => { console.error(error) }
        )

      });
  }

}


