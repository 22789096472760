import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { NonAdminComponent } from './nonAdmin/non-admin.component';
import { LoginComponent } from './nonAdmin/login/login.component';
import { ProfileComponent } from './nonAdmin/profile/profile.component';
import { AuthGuardService } from './guards/auth-guard.service';
import { DisplayComponent } from './nonAdmin/profile/display/display.component';
import { EditComponent } from './nonAdmin/profile/edit/edit.component';
import { LandingComponent } from './landing/landing.component';
import { AccountActivationComponent } from './accountActivation/account-activation.component';
import { PrivacyPolicyComponent } from './shared/privacy-policy/privacy-policy.component';
import { ForgotPasswordComponent } from './shared/forgot-password/forgot-password.component';
import { ChangePasswordComponent } from './shared/change-password/change-password.component';


// import { AdminComponent } from './nonAdmin/admin.component';
// import { LoginComponent } from './admin/login/login.component';
// import { RegistrationComponent } from './admin/registration/registration.component';
// import { DashboardComponent } from './admin/dashboard/dashboard.component';
// import { OverviewComponent } from './admin/dashboard/overview/overview.component';
// import { UserAddComponent } from './admin/dashboard/user-add/user-add.component';
// import { UserListComponent } from './admin/dashboard/user-list/user-list.component';
// import { CertificationComponent } from './admin/dashboard/certification/certification.component';
// import { CertifiedListComponent } from './admin/dashboard/certified-list/certified-list.component';
// import { AdminAuthGuardService } from './guards/admin-auth-guard.service';

// import { AppAdminModule } from './app-admin.module';



const routes: Routes = [
  { path: '', component: LandingComponent },
  { path: 'forgot_password', component: ForgotPasswordComponent  },
  { path: 'change_password', component: ChangePasswordComponent  },
  { path: '', component: NonAdminComponent, children: [
    { path: 'activation', component: AccountActivationComponent },
    { path: 'user', component: LoginComponent},
    { path: 'user/login', component: LoginComponent },
    { path: 'user/signup', component: LoginComponent },


    { path: 'profile', component: ProfileComponent, canActivate:[AuthGuardService], children:
      [
        { path: '', component: DisplayComponent },
        { path: 'edit', component: EditComponent },
        { path: '**', redirectTo: '', pathMatch: 'full' }
      ]
    },
    {path: 'privacy_policy', component: PrivacyPolicyComponent}
    // { path: '**', redirectTo: '', pathMatch: 'full' }
  ]},

  { path: 'admin', loadChildren: () => import('./app-admin.module').then(m => m.AppAdminModule),
    // children:
    // [
      // { path: 'login', component: LoginComponent },
      // { path: 'registration', component: RegistrationComponent},
      // { path: '', component: DashboardComponent, canActivate:[AdminAuthGuardService], children:
      //   [
      //     { path: 'service_overview', component: OverviewComponent },
      //     { path: 'user_add', component: UserAddComponent },
      //     { path: 'users_overview', component: UserListComponent },
      //     { path: 'certification', component: CertificationComponent },
      //     { path: 'certified', component: CertifiedListComponent },
      //     // { path: '**', redirectTo: '', pathMatch: 'full' }
      //   ]
      // },
      // { path: '**', redirectTo: 'login', pathMatch: 'full' }
    // ]
  },
  { path: '**', redirectTo: '', pathMatch: 'full' }

];

@NgModule({
  imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled', anchorScrolling: 'enabled'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
