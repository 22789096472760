import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-admin-navbar',
  templateUrl: './admin-navbar.component.html',
  styleUrls: ['./admin-navbar.component.scss']
})
export class AdminNavbarComponent implements OnInit {
  @Output() childClickEvent = new EventEmitter<void>();
  constructor() { }

  ngOnInit() {
  }

  sendClickEvent() {
    this.childClickEvent.emit();
  }

}
