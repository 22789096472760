import { Component, OnInit, ViewChild } from '@angular/core';

import { MatTableDataSource, MatSort, MatPaginator, MatSlideToggleChange, MatDialog } from '@angular/material';
import { SelectionModel } from '@angular/cdk/collections';
import { AdminService } from 'src/app/admin/services/admin.service';
import { Subscription } from 'rxjs';

import { User } from 'src/app/shared/interfaces/interfaces';
import { AppService } from 'src/app/shared/services/app.service';
import * as moment from 'moment';
import { trigger } from '@angular/animations';
import { fadeIn } from 'src/app/shared/animations/animations';
import { DeletionDialogComponent } from 'src/app/shared/dialogs/deletion-dialog/deletion-dialog.component';
import { TableUtil } from './tableUtil';


// export interface UserListTable {
//   name: string;
//   surname: string;
//   address: string;
// }

const displayedColumns = [
  { def: ['select', 'name', 'surname', 'birthday', 'address', 'date_created', 'date_updated', 'status', 'registered_by', 'delete'], expanded: true },
  { def: ['select', 'name', 'surname', 'status', 'registered_by'], expanded: false }
];

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss'],
  animations: [
    trigger('fadeIn', fadeIn()),
  ]
})


export class UserListComponent implements OnInit {

  constructor(public adminService: AdminService, private appService: AppService, private dialog: MatDialog) { }


  dataSource = new MatTableDataSource<User>()
  selection = new SelectionModel<User>(false, []);
  users: User[] = []

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  subscriptions = new Subscription

  // selectedUser = dummyUsers[0]
  ngOnInit() {
    // this.dataSource.data = dummyUsers
    // this.dataSource.sort = this.sort


    this.subscriptions.add(this.selection.changed.subscribe(
      selection => {
        console.log("selection");
        console.log(selection);


        if (selection.added.length) {
          this.adminService.selectedUser$.next(selection.added[0])
        } else {
          this.adminService.selectedUser$.next(undefined)
        }
      },
      error => {
        console.error(error)
      }
    ))

    // this.subscriptions.add(this.adminService.userChanged$.subscribe(
    //   user => {
    //     console.log(user)
    //     this.fetchUsers()
    //     this.selection.toggle(user)

    //     // this.highlightRow(user)
    //   },
    //   error => {
    //     console.error(error)
    //   }
    // ))

    this.fetchUsers()
  }

  deleteRow(row, event) {
    console.log("row_______");
    console.log(row);
    event.stopPropagation();

    const dialogRef = this.dialog.open(DeletionDialogComponent, {
      data: {
        user: row,
        title: "Διαγραφή χρήστη",
        message: "Είστε σίγουροι ότι επιθυμείτε να διαγράψετε τον χρήστη?"
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'confirmed') {
        // Implement the logic to delete the user here
        console.log('Deleting row:', row._id);

        let user_id = {
          "id": [row._id]
        }
        this.appService.delete_user(user_id).subscribe(
          data => {
            this.fetchUsers();
          }, error => {
            console.error(error)
          }
        )

      }
    });

  }

  toPdf(row, event){

    event.stopPropagation();


    const userId =  row._id;
    this.appService.exportToPdf(userId).subscribe((response: Blob) => {
      const file = new Blob([response], { type: 'application/pdf' });
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL, '_blank');
    }, error => {
      console.error('Error downloading PDF:', error);
    });



  }

  fetchUsers() {
    this.appService.fetch_manager_users().subscribe(
      data => {
        data.forEach(user => {
          user['age'] = moment().diff(new Date(user.birthday), 'years')
        })
        this.users = data
        this.dataSource.data = data
        this.dataSource.sort = this.sort
        this.dataSource.paginator = this.paginator
        this.dataSource.sortingDataAccessor = (item, property): string | number => {
          switch (property) {
            case 'date_created': return new Date(item.created).getTime();
            case 'date_updated': return new Date(item.updated).getTime();
            default: return item[property];
          }
        }

        const pendingApplications = data.filter(user => user.status === 'pending')
        this.adminService.usersApplications = undefined
        if (pendingApplications.length) this.adminService.usersApplications = pendingApplications.length

      },
      error => console.error(error)
    )
  }

  clubRegisteredUsersToggled(event: MatSlideToggleChange) {
    if (event.checked) {
      this.dataSource.data = this.users.filter(user => user.owner[0].club[0])
    } else {
      this.dataSource.data = this.users
    }
  }

  getDisplayedColumns(): string[] {
    const isExpanded = this.selection.isEmpty()

    return displayedColumns
      .find(dc => isExpanded === dc.expanded).def
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.dataSource.filter = filterValue;
  }

  highlightRow(user, event?) {
    // const isDeleteButton = (event.target as HTMLElement).classList.contains('delete-button');
    const isDeleteButton = event && (event.target as HTMLElement).classList.contains('delete-button');

    if (!isDeleteButton) {
      this.selection.toggle(user);
    }
    // this.selection.toggle(user)
  }


  exportToExcel() {
    console.log("clicked!!!");
    let pass_object = {
      "excelFile": 1
    }

    this.appService.exportToExcel(pass_object).subscribe((blob)  => {
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = 'example.xlsx';
      link.click();
    }, error => console.error(error));

  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe()
    this.adminService.selectedUser$.next(undefined)
  }



}


