import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppService } from '../shared/services/app.service';

@Component({
  selector: 'app-account-activation',
  templateUrl: './account-activation.component.html',
  styleUrls: ['./account-activation.component.scss']
})
export class AccountActivationComponent implements OnInit {

  constructor(
    private activatedRoute: ActivatedRoute,
    private appService: AppService,
    private router: Router
  ) { }

  completed: boolean = true
  successfulActivation: boolean = false
  error: boolean = false

  queryParams: {msg: string, email: string}

  ngOnInit() {
    if (
      this.activatedRoute.snapshot.queryParams && 
      this.activatedRoute.snapshot.queryParams.msg &&
      this.activatedRoute.snapshot.queryParams.email)
    {
      this.try_account_activation()
    }
    else 
    {
      this.router.navigate(["/"])
    }
  }

  try_account_activation() {
    const queryParams = this.activatedRoute.snapshot.queryParams
    this.appService.try_activation(queryParams.msg, queryParams.email).subscribe(
      data => {
        this.completed = true
        if (data.message === "ACCOUNT_ACTIVATED") this.successfulActivation = true
      },
      error => {
        this.completed = true
        this.error = true
        console.error(error)
      }
    )
  }

}
