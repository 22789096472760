import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AppService } from './shared/services/app.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'saveAmeaPortalWeb';

  constructor(
    private translate: TranslateService,
    private appService: AppService
  ) {
    this.translate.setDefaultLang('el')
    this.translate.use('el')
  }
}
