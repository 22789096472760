import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormArray, NgForm } from '@angular/forms';

import { Router } from '@angular/router';
import { AppService } from 'src/app/shared/services/app.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { CustomValidators } from '../../../shared/customValidators/custom.validators';

@Component({
  selector: 'app-myaccount',
  templateUrl: './myaccount.component.html',
  styleUrls: ['./myaccount.component.scss']
})
export class MyaccountComponent implements OnInit {

  constructor(
    private appService: AppService,
    private toast: ToastrService,
    private translate: TranslateService,
    private router: Router
  ) { }

  userForm = new FormGroup({
    currentpassword: new FormControl('', [Validators.required]),
    newpassword: new FormControl('', [Validators.required, CustomValidators.passwordValidator()]),
    confirmpassword: new FormControl('', [Validators.required, CustomValidators.passwordValidator()])
  }, CustomValidators.matchingPassValidatorMyAccount )

  ngOnInit() {
  }


  hideP = true;
  hideNP = true;
  hideCNP = true;
  changePass() {

    if (this.userForm.valid) {

    const userToAdd = {
      currentpassword: this.userForm.value.currentpassword,
      newpassword: this.userForm.value.newpassword,
      confirmpassword: this.userForm.value.confirmpassword
    }

    this.appService.change_user_pass(userToAdd).subscribe(data => {

      let _objMsg = Object(data);
      console.log("_objMsg");
      console.log(_objMsg);


      if (_objMsg.message == "UPDATED") {

        this.toast.success(this.translate.instant('SUCCESS.SUCCESSFUL_EDIT'))

        this.appService.logout().subscribe(
          data => { },
          error => console.error(error)
        )
        this.router.navigate(['admin', 'login'])

      }
    },
      error => {
        console.error(error)
        if (error.error && error.error.message === "WRONG_CURRENT_PASSWORD") {
          this.userForm.get('currentpassword').setErrors({ wrongPassword: true });
        }
        this.toast.error(this.translate.instant('ERRORS.USER_STATUS_UPDATED'))
      })

  }
}

}
