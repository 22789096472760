import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { mergeMap } from 'rxjs/operators'

import { LoggedUser } from '../shared/interfaces/interfaces';


@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private httpClient: HttpClient) { }

  public isAuthenticated() {
    const uuid = localStorage.getItem('uuid');
    const email = localStorage.getItem('email');

    return this.httpClient.get<any>('assets/env_specific/config.json').pipe(
      mergeMap(config => {
        // return of(true)
        return this.httpClient.post<LoggedUser[]>(`${config['API']}/users/isAuthenticate`, { token: uuid, email: email })
      })
    )
  }

  login(loginData: {email: string, password: string}) {

    return this.httpClient.get<any>('assets/env_specific/config.json').pipe(
      mergeMap(config => {
        // return of(loginData)
        return this.httpClient.post<LoggedUser>(`${config['API']}/users/login`, loginData)
      })
    )
  }

}
