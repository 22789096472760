import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { User } from 'src/app/shared/interfaces/interfaces';


@Injectable({
  providedIn: 'root'
})
export class AdminService {

  constructor() {  }

  selectedUser$ = new BehaviorSubject<User | undefined>(undefined);

  userChanged$ = new Subject<User>()

  clubApplications: number
  serviceApplications: number

  usersApplications: number

}
