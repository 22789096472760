import { FormArray, AbstractControl } from '@angular/forms';
import { AppService } from '../services/app.service';

import { map } from "rxjs/operators";


const EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export class CustomValidators {


  static getEmailRegex() {
    return EMAIL_REGEX
  }

  static checkEmailTaken(appService: AppService) {
    return (control: AbstractControl) => {
      return appService.checkEmailAvailability(control.value).pipe(map(res => {
        return res.status ? { emailTaken: true } : null
      }))
    }
  }

  static multipleCheckboxRequireOne(fa: FormArray) {
    let valid = false;

    for (let x = 0; x < fa.length; ++x) {
      if (fa.at(x).value) {
        valid = true;
        break;
      }
    }
    return valid ? null : {
      multipleCheckboxRequireOne: true
    };
  }

  static matchingPassValidator(AC: any) {
    let pass = AC.get('password1');
    let confirmPass = AC.get('password2');

    if (pass.value != confirmPass.value) {
      confirmPass.setErrors({ noMatchingPassword: true });
    } else {
      confirmPass.setErrors(null);
      return null
    }
  }

  static matchingPassValidatorMyAccount(AC: any) {
    let pass = AC.get('newpassword');
    let confirmPass = AC.get('confirmpassword');

    if (pass.value != confirmPass.value) {
      confirmPass.setErrors({ noMatchingPassword: true });
    } else {
      // confirmPass.setErrors(null);
      return null
    }
  }



  static passwordValidator(): any {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const password = control.value;
      const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%?&])[A-Za-z\d@$!%?&]{8,}$/;

      if (!password || regex.test(password)) {
        return null; // Return null if the password is valid
      } else {
        return { 'invalidPassword': true }; // Return an error object if the password is invalid
      }
    };
  }

  static autocompleteValidator(options: string[]) {
    return (control: AbstractControl) => {
      return (options.includes(control.value)) ? null : { valueNotIncludedInAutocomplete: true }
    }
  }

}
