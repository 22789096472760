import { Component, OnInit, NgZone, ViewChild } from '@angular/core';
import { AppService } from 'src/app/shared/services/app.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { FormGroup, FormControl, Validators, NgForm } from '@angular/forms';
import { Region } from 'src/app/shared/interfaces/interfaces';

import { CustomValidators } from 'src/app/shared/customValidators/custom.validators'

import * as L from 'leaflet';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { MatDialog } from '@angular/material';
import { ApplicationDialogComponent } from 'src/app/shared/dialogs/application-dialog/application-dialog.component';
import { ActivatedRoute, Router } from '@angular/router';


@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss']
})
export class RegistrationComponent implements OnInit {

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private appService: AppService,
    private zone: NgZone,
    private toast:ToastrService,
    private translate: TranslateService,
    private dialog: MatDialog) { }

  sectorForm = new FormGroup({
    // type: new FormControl('', Validators.required),
    accountForm: new FormGroup({
      email: new FormControl('', [Validators.required, Validators.pattern(CustomValidators.getEmailRegex())], CustomValidators.checkEmailTaken(this.appService)),
      passwordForm: new FormGroup({
        password1: new FormControl('', [Validators.required, CustomValidators.passwordValidator()]),
        password2: new FormControl('', [Validators.required, CustomValidators.passwordValidator()])
      }, CustomValidators.matchingPassValidator),
      name: new FormControl('', Validators.required),
      surname: new FormControl('', Validators.required)
    }),
    name: new FormControl('', Validators.required),
    phone: new FormControl('', Validators.pattern(/^[0-9]*$/)),
    administrative_region: new FormControl('', Validators.required),
    municipality: new FormControl({value: '', disabled: true }, Validators.required),
    address: new FormControl('', Validators.required),
    coordinates: new FormControl([], Validators.required)
  })

  mapInit: L.MapOptions
  mapLayer = []
  mapZoom:number
  mapCenter: L.LatLng

  _city;
  _region;

  regions: Region[]
  administrativeRegions: string[]
  municipalities: string[]

  filteredAdminRegion: Observable<string[]>
  filteredMunicipalities: Observable<string[]>

  sectorSelected: 'manager'|'operator'

  @ViewChild('sectorFormDirective', {static: true}) private sectorFormDirective: NgForm


  ngOnInit() {
    // Url check for /registration/*club* or /registration/*operator*
    const urlFirstchild = this.activatedRoute.snapshot.firstChild
    if (urlFirstchild) {
      if (urlFirstchild.routeConfig.path === 'club') this.sectorSelected = 'manager'
      if (urlFirstchild.routeConfig.path === 'operation') this.sectorSelected = 'operator'
    } else {
      this.router.navigate(['admin', 'login'])
    }

    const openStreetMaps = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', { maxZoom: 18, attribution: '© <a tabindex="-1" href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, <a tabindex="-1" href="http://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery © <a tabindex="-1" href="http://mapbox.com">Mapbox</a>' })

    this.mapInit = {
      layers: [openStreetMaps],
      zoom: 12,
      center: L.latLng(38.254028, 21.7583104)
    }

    this.fetchMunicipalityDetails();

    this.fetchRegions()

  }


  submitSector() {

    this.sectorForm.get('coordinates').markAsTouched()

    if (this.sectorForm.valid && this.mapLayer.length > 0) {

      let __region = this._region;
      let __city = this._city;

      if(this.sectorForm.value.administrative_region){
        __region = this.sectorForm.value.administrative_region;
      }

      if(this.sectorForm.value.municipality){
        __city = this.sectorForm.value.municipality;
      }

      const requestObj = {
        selectedRole: this.sectorSelected,
        email: this.sectorForm.value.accountForm.email,
        password: this.sectorForm.value.accountForm.passwordForm.password1,
        firstname: this.sectorForm.value.accountForm.name,
        surname: this.sectorForm.value.accountForm.surname,
        sectorName: this.sectorForm.value.name,
        phoneNumber: this.sectorForm.value.phone,
        address: this.sectorForm.value.address,
        loc: { type: 'Point', coordinates: this.sectorForm.value.coordinates },
        region: { administrative: __region, municipality: __city }
      }
      // console.log(requestObj)

      this.appService.submitSector(requestObj).subscribe(
        data => {
          this.openRequestStatusDialog('success', this.translate.instant('SUCCESS.SECTOR_ADD'))
          this.resetForm()
        },
        error => {
          console.error(error)
          if (error.status === 400) {
            switch (error.error.message) {
              case 'ACCOUNT_NOT_SAVED':
                this.openRequestStatusDialog('error', this.translate.instant('ERRORS.ENTRY_DUPLICATE_ACCOUNT'))
                break
              case 'CLUB_NOT_SAVED':
              case 'OPERATION_NOT_SAVED':
                this.openRequestStatusDialog('error', this.translate.instant('ERRORS.ENTRY_DUPLICATE_SECTOR'))
                break
              default:
                this.openRequestStatusDialog('error', this.translate.instant('ERRORS.ENTRY_GENERIC'))
            }
          } else {
            this.openRequestStatusDialog('error', this.translate.instant('ERRORS.SERVICES_ERR'))
          }
        }
      )
    }
  }

  openRequestStatusDialog(status: 'success' | 'error', message: string) {
    this.dialog.open(ApplicationDialogComponent, {data: {requestResult: status, requestMessage: message}})
  }

  resetForm() {
    this.sectorForm.reset()
    this.sectorFormDirective.resetForm()
    this.mapLayer = []
    this.mapZoom = 12
    this.mapCenter = L.latLng(38.254028, 21.7583104)

    this.fetchMunicipalityDetails();

  }


  fetchRegions() {
    this.appService.get_regions().subscribe(
      data => {
        this.administrativeRegions = data.map( el => el.region)
        this.regions = data
        this.appService.regions = data
        this.filteredAdminRegion = this.sectorForm.get('administrative_region').valueChanges
        .pipe(
          startWith(''),
          map(value => this._filterAdministrativeReg(this.administrativeRegions, value))
        )
      },
      error => {
        console.error(error)
        this.toast.error(this.translate.instant('ERRORS.SERVICES_ERR'))
      }
    )
  }

  _filterAdministrativeReg(options: string[], value: string): string[] {
    this.sectorForm.get('administrative_region').setValidators(CustomValidators.autocompleteValidator(options))

    const filterValue = value ? value.toLowerCase() : ''
    const regionIndex = this.administrativeRegions.indexOf(value)
    // console.log(this.userForm.get('administrative_region').value)
    if (regionIndex !== -1) {
      this.sectorForm.get('municipality').enable()
      this.municipalities = this.regions[regionIndex].municipalities.map( el => el.city.name)
      this.filteredMunicipalities = this.sectorForm.get('municipality').valueChanges
      .pipe(
          startWith(''),
          map(value => this._filterMunicipalities(this.municipalities, value))
      )
    } else if (this.sectorForm.get('municipality').value){
      this.sectorForm.get('municipality').disable()
      this.sectorForm.get('municipality').reset()

      this.sectorForm.get('municipality').enable()
      this.sectorForm.patchValue({municipality: this._city });
      this.sectorForm.get('municipality').disable()
    }
    return options.filter(option => option.toLowerCase().includes(filterValue))
  }

  _filterMunicipalities(options: string[], value: string): string[] {
    this.sectorForm.get('municipality').setValidators(CustomValidators.autocompleteValidator(options))

    const filterValue = value ? value.toLowerCase() : ''
    return options.filter(option => option.toLowerCase().includes(filterValue))
  }

  pointOnMap(lat, lng) {
    this.mapCenter = L.latLng(lat, lng);
    this.mapZoom = 18;

    this.mapLayer = []
    this.mapLayer.push(L.marker([lat,lng], {
      icon: L.icon({
        iconAnchor: [12, 41],
        iconUrl: "assets/marker-icon.png",
        shadowUrl: "assets/marker-shadow.png"
      })
    }))

    this.sectorForm.patchValue({coordinates:[lng, lat]})
  }

  addressToMap(address) {
    if (address) {
      this.appService.get_coordinates(address).subscribe(
        data => {
          if (data.results.length > 0) {
            this.pointOnMap(data.results[0].geometry.location.lat, data.results[0].geometry.location.lng)
            this.sectorForm.patchValue({address:data.results[0].formatted_address})
          }
        },
        error => {
          console.error(error)
        }
      )
    }
  }

  onMapReady(map: L.Map){
    // map.on ('layeradd', (ev:L.LeafletMouseEvent) => {
    //     map.setView(this.user.loc.coordinates, 18);
    // });
    map.on("click", (ev:L.LeafletMouseEvent) => {
      this.zone.run( () => {
        this.pointOnMap(ev.latlng.lat, ev.latlng.lng)

        this.appService.get_address(ev.latlng.lat, ev.latlng.lng).subscribe(
          data => {
            if (data.results.length > 0) {
              this.sectorForm.patchValue({address:data.results[0].formatted_address})
            }
          },
          error => {
            console.error(error)
          }
        )
      })
    })
  }

  async fetchMunicipalityDetails() {

    await this.appService.getMunicipalityDetails().subscribe(
      async data => {

        // const openStreetMaps = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', { maxZoom: 18, attribution: '© <a tabindex="-1" href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, <a tabindex="-1" href="http://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery © <a tabindex="-1" href="http://mapbox.com">Mapbox</a>' })

        // this.mapInit = {
        //   layers: [openStreetMaps],
        //   zoom: data[0].center.zoom,
        //   center: L.latLng(data[0].center.coordinates[1], data[0].center.coordinates[0])
        // }

        this.mapZoom = data[0].center.zoom;
        this.mapCenter = L.latLng(data[0].center.coordinates[1],data[0].center.coordinates[0])

        this._city = data[0].city;
        this._region = data[0].administrative_region;

        this.sectorForm.patchValue({administrative_region: data[0].administrative_region });
        this.sectorForm.get('municipality').enable()
        this.sectorForm.patchValue({municipality: data[0].city });
        this.sectorForm.get('municipality').disable()
        this.sectorForm.get('administrative_region').disable()
        this.sectorForm.get('municipality').disable()
        //this.filteredSectors$ = this.fetchFilteredSectors(data[0].city)
        //this.editForm.get('sector').enable()

      });
  }

  handleChildClick() {
    console.log('Child component clicked in parent!12334');
    // logic to handle the click event from the child.
    this.router.navigate(['./']);

  }

}
