import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { CustomValidators } from 'src/app/shared/customValidators/custom.validators';
import { AppService } from 'src/app/shared/services/app.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

  constructor(
    private router: Router,
    private appService: AppService,
    private toast: ToastrService,
    private translate: TranslateService,


  ) { }


  forgotPasswordForm = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.pattern(CustomValidators.getEmailRegex())]),
  })
  ngOnInit() {
  }
  handleChildClick() {
    this.router.navigate(['./']);

  }

  submit() {

    if (this.forgotPasswordForm.valid) {
      this.forgotPasswordForm.value
      console.log("______________");
      console.log(this.forgotPasswordForm.value);
      let email = this.forgotPasswordForm.value
      this.appService.forgotPassword(email).subscribe(
        data => {
          console.log("data__________");
          console.log(data);
          if (data["msg"] === "CHANGED") {
            this.toast.success('Σας έχουμε αποστείλει στο email σας έναν σύνδεσμο για να αποκτήσετε ξανά πρόσβαση στο λογαριασμό σας.')
            this.router.navigate(['./']);

          } else if (data["msg"] === "NO_USER_FOUND") {

            this.toast.error(this.translate.instant('ERRORS.SERVICES_ERR'))
            this.router.navigate(['./']);

          } else {
            this.toast.error(this.translate.instant('ERRORS.SERVICES_ERR'))
            this.router.navigate(['./']);

          }

        }, (error) => {
          console.log(error);

          this.toast.error(this.translate.instant('ERRORS.SERVICES_ERR'))
          this.router.navigate(['./']);
        }
      );


    }
  }


}
