import { trigger } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { fadeIn } from '../animations/animations';

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.scss'],
  animations: [ 
    trigger('fadeIn', fadeIn()),
   ]
})
export class PrivacyPolicyComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
