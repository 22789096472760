import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { TranslateModule, TranslateLoader, TranslateService } from '@ngx-translate/core';

import { NgProgressModule } from '@ngx-progressbar/core';
import { NgProgressHttpModule } from '@ngx-progressbar/http';

import { LeafletModule } from '@asymmetrik/ngx-leaflet';

import { ToastrModule } from 'ngx-toastr'



import { AdminComponent } from './admin/admin.component';

import { LoginComponent } from './admin/login/login.component';
import { AdminNavbarComponent } from './admin/navbar/admin-navbar.component';
import { RegistrationComponent } from './admin/registration/registration.component';
import { AppMaterialModule } from './app-material.module';
import { DashboardComponent } from './admin/dashboard/dashboard.component';
import { OverviewComponent } from './admin/dashboard/overview/overview.component';
import { UserListComponent } from './admin/dashboard/user-list/user-list.component';
import { UserAddComponent } from './admin/dashboard/user-add/user-add.component';
import { UserProfileComponent } from './admin/dashboard/user-profile/user-profile.component';
import { CertificationComponent } from './admin/dashboard/certification/certification.component';
import { CertifiedClubsListComponent } from './admin/dashboard/certified-clubs-list/certified-clubs-list.component';
import { CertifiedServicesListComponent } from './admin/dashboard/certified-services-list/certified-services-list.component';
import { AppAdminRoutingModule } from './app-admin-routing.module';
import { ConfirmationDialogComponent } from './shared/dialogs/confirmation-dialog/confirmation-dialog.component';
import { MyaccountComponent } from './admin/dashboard/myaccount/myaccount.component';
import { DeletionDialogComponent } from './shared/dialogs/deletion-dialog/deletion-dialog.component';
import { ForgotPasswordComponent } from './shared/forgot-password/forgot-password.component';
import { ChangePasswordComponent } from './shared/change-password/change-password.component';

// import { UserApplicationsComponent } from './admin/dashboard/user-applications/user-applications.component';



@NgModule({
  declarations: [
    AdminComponent,
    LoginComponent,
    AdminNavbarComponent,
    RegistrationComponent,
    DashboardComponent,
    OverviewComponent,
    UserListComponent,
    UserAddComponent,
    UserProfileComponent,
    CertificationComponent,
    CertifiedClubsListComponent,
    CertifiedServicesListComponent,
    ConfirmationDialogComponent,
    MyaccountComponent,
    DeletionDialogComponent,
    ForgotPasswordComponent,
    ChangePasswordComponent
    // UserApplicationsComponent
  ],
  imports: [
    CommonModule,
    AppAdminRoutingModule,
    AppMaterialModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    TranslateModule.forChild(),
    LeafletModule,
    ToastrModule,
    NgProgressModule,
    NgProgressHttpModule



    // AppMaterialModule

  ],
  exports: [
    // AdminComponent,
    // LoginComponent,
    // AdminNavbarComponent,
    // RegistrationComponent

  ],
  entryComponents: [ConfirmationDialogComponent,DeletionDialogComponent]
})
export class AppAdminModule { }
