import { trigger } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { fadeIn } from '../shared/animations/animations';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss'],
  animations: [ 
    trigger('fadeIn', fadeIn()),
   ]
})
export class LandingComponent implements OnInit {

  constructor(
    private router: Router
  ) { }

  ngOnInit( ) {
  }

  navigateToUserLogin() {
    this.router.navigate(['user', 'login'])
  }

  navigateToUserSignup() {
    this.router.navigate(['user', 'signup'])
  }

  navigateToClubsSignup() {
    this.router.navigate(['admin', 'registration', 'club'])
  }

  navigateToServicesLogin() {
    this.router.navigate(['admin', 'login'])
  }
  
  navigateToServicesSignup() {
    this.router.navigate(['admin', 'registration', 'operation'])
  }

}
